import React from 'react';

const suggestions = [
  "What's a healthy breakfast for weight loss?",
  "Can you suggest high-protein vegetarian meals?",
  "How can I reduce sugar cravings?",
  "What should I eat before and after workouts?",
  "Help me plan a balanced weekly meal plan",
  "What are good snacks for energy throughout the day?"
];

interface ChatSuggestionsProps {
  onSuggestionClick: (suggestion: string) => void;
}

export default function ChatSuggestions({ onSuggestionClick }: ChatSuggestionsProps) {
  return (
    <div className="grid grid-cols-2 gap-4 mt-6">
      {suggestions.map((suggestion, index) => (
        <button
          key={index}
          onClick={() => onSuggestionClick(suggestion)}
          className="text-left p-3 text-sm bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
        >
          {suggestion}
        </button>
      ))}
    </div>
  );
}