import React from 'react';
import { AnalysisResponse } from '../types';
import { 
  ArrowLeft, 
  Flame, 
  Beef, 
  Cookie, 
  Droplets,
  Pizza,
  Sandwich,
  Coffee,
  Utensils,
  Clock
} from 'lucide-react';

interface ResultsProps {
  result: AnalysisResponse;
  isLoading: boolean;
  onBack: () => void;
}

const getFoodIcon = (food: string) => {
  const name = food.toLowerCase();
  if (name.includes('pizza')) return Pizza;
  if (name.includes('sandwich') || name.includes('wrap')) return Sandwich;
  if (name.includes('coffee') || name.includes('drink')) return Coffee;
  return Utensils;
};

const MEAL_ICONS = {
  breakfast: '🌅',
  lunch: '☀️',
  dinner: '🌙',
  snack: '🍎'
};

const MEAL_TITLES = {
  breakfast: 'Breakfast',
  lunch: 'Lunch',
  dinner: 'Dinner',
  snack: 'Snack'
};

export default function Results({ result, isLoading, onBack }: ResultsProps) {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header with back button and meal type */}
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4">
          <button
            onClick={onBack}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors"
          >
            <ArrowLeft className="w-6 h-6 text-gray-900 dark:text-white" />
          </button>
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Food Results</h2>
        </div>
        <div className="flex items-center gap-2 bg-gray-100 dark:bg-gray-800 px-4 py-2 rounded-full">
          <Clock className="w-4 h-4 text-gray-700 dark:text-gray-300" />
          <span className="font-medium text-gray-700 dark:text-gray-300">
            {MEAL_ICONS[result.mealType]} {MEAL_TITLES[result.mealType]}
          </span>
        </div>
      </div>

      {/* Detected Foods List */}
      <div className="bg-white dark:bg-gray-800 rounded-2xl p-6">
        <h3 className="text-xl font-semibold mb-6 text-gray-900 dark:text-white">Detected Foods</h3>
        <div className="space-y-4">
          {result.foods.map((food, index) => {
            const Icon = getFoodIcon(food);
            return (
              <div 
                key={index}
                className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
              >
                <div className="flex items-center gap-4">
                  <div className="p-2 bg-blue-100 dark:bg-blue-900/50 rounded-lg">
                    <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">
                      {food}
                    </h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        
        {/* Total Calories Summary */}
        <div className="mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-orange-100 dark:bg-orange-900/50 rounded-lg">
                <Flame className="w-6 h-6 text-orange-500" />
              </div>
              <div>
                <h4 className="font-medium text-gray-900 dark:text-white">
                  Total Calories
                </h4>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Combined caloric content
                </p>
              </div>
            </div>
            <div className="text-2xl font-bold text-gray-900 dark:text-white">
              {result.calories} <span className="text-base font-normal text-gray-500 dark:text-gray-400">cal</span>
            </div>
          </div>
        </div>
      </div>

      {/* Main Stats Grid */}
      <div className="grid grid-cols-2 gap-4">
        {/* Total Calories Card */}
        <div className="col-span-2 bg-gradient-to-br from-orange-500 to-red-600 rounded-2xl p-6 text-white">
          <div className="flex items-center gap-3 mb-2">
            <Flame className="w-6 h-6" />
            <h3 className="text-lg font-semibold">Total Calories</h3>
          </div>
          <p className="text-4xl font-bold">{result.calories}</p>
          <p className="text-sm opacity-80 mt-1">calories</p>
        </div>

        {/* Health Score Card */}
        <div className="bg-gradient-to-br from-green-500 to-emerald-600 rounded-2xl p-6 text-white">
          <div className="flex items-center gap-3 mb-2">
            <Utensils className="w-6 h-6" />
            <h3 className="text-lg font-semibold">Health Score</h3>
          </div>
          <p className="text-4xl font-bold">{result.healthScore}</p>
          <p className="text-sm opacity-80 mt-1">out of 10</p>
        </div>

        {/* Detected Items Card */}
        <div className="bg-gradient-to-br from-blue-500 to-indigo-600 rounded-2xl p-6 text-white">
          <div className="flex items-center gap-3 mb-2">
            <Utensils className="w-6 h-6" />
            <h3 className="text-lg font-semibold">Detected Items</h3>
          </div>
          <p className="text-4xl font-bold">{result.foods.length}</p>
          <p className="text-sm opacity-80 mt-1">items analyzed</p>
        </div>
      </div>

      {/* Macronutrients Section */}
      <div className="bg-white dark:bg-gray-800 rounded-2xl p-6 space-y-6">
        <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">Macronutrients</h3>
        <div className="grid grid-cols-3 gap-4">
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Beef className="w-5 h-5 text-red-500" />
              <span className="text-sm font-medium text-gray-900 dark:text-white">Protein</span>
            </div>
            <p className="text-2xl font-bold text-gray-900 dark:text-white">{result.nutrition.protein}g</p>
          </div>
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Cookie className="w-5 h-5 text-amber-500" />
              <span className="text-sm font-medium text-gray-900 dark:text-white">Carbs</span>
            </div>
            <p className="text-2xl font-bold text-gray-900 dark:text-white">{result.nutrition.carbs}g</p>
          </div>
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Droplets className="w-5 h-5 text-blue-500" />
              <span className="text-sm font-medium text-gray-900 dark:text-white">Fats</span>
            </div>
            <p className="text-2xl font-bold text-gray-900 dark:text-white">{result.nutrition.fats}g</p>
          </div>
        </div>
      </div>
    </div>
  );
}