import React from 'react';
import { Helmet } from 'react-helmet-async';
import Hero from '../components/home/Hero';
import HowItWorks from '../components/home/HowItWorks';
import Features from '../components/home/Features';
import Testimonials from '../components/home/Testimonials';
import FAQ from '../components/home/FAQ';
import Pricing from '../components/home/Pricing';
import CallToAction from '../components/home/CallToAction';

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>Calofood | AI-Powered Nutrition Analysis & Meal Planning</title>
        <meta 
          name="description" 
          content="Transform your diet with AI-powered nutrition tracking. Analyze food photos, get personalized meal plans, and make healthier restaurant choices with Calofood." 
        />
      </Helmet>
      <div>
        <Hero />
        <div id="how-it-works">
          <HowItWorks />
        </div>
        <div id="features">
          <Features />
        </div>
        <div id="testimonials">
          <Testimonials />
        </div>
        <div id="faq">
          <FAQ />
        </div>
        <div id="pricing">
          <Pricing />
        </div>
        <CallToAction />
      </div>
    </>
  );
}