import React from 'react';
import { Plus, Minus } from 'lucide-react';

const faqs = [
  {
    question: 'How accurate is the calorie and nutrition analysis?',
    answer: 'Our AI-powered analysis is highly accurate, trained on millions of food images. For packaged foods, accuracy is typically above 95%, while for homemade meals, it maintains an accuracy of 85-90%.',
  },
  {
    question: 'Can I track my diet over time?',
    answer: 'Yes! The app maintains a comprehensive history of your meals, providing daily, weekly, and monthly insights into your nutritional patterns and progress.',
  },
  {
    question: 'Does the app work for complex meals or mixed dishes?',
    answer: 'Absolutely! Our AI can identify and analyze multiple ingredients in complex dishes, providing detailed nutritional information for each component.',
  },
  {
    question: 'Is my data private and secure?',
    answer: 'We take privacy seriously. All your data is encrypted and stored securely. We never share your personal information with third parties without your explicit consent.',
  },
];

export default function FAQ() {
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);

  return (
    <section className="py-20 bg-white dark:bg-gray-800">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Find answers to common questions about our service
          </p>
        </div>

        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div 
              key={index}
              className="border dark:border-gray-700 rounded-xl overflow-hidden"
            >
              <button
                className="w-full flex items-center justify-between p-6 text-left bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
                <span className="font-semibold text-gray-900 dark:text-white">
                  {faq.question}
                </span>
                {openIndex === index ? (
                  <Minus className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                ) : (
                  <Plus className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                )}
              </button>
              {openIndex === index && (
                <div className="p-6 bg-white dark:bg-gray-800">
                  <p className="text-gray-600 dark:text-gray-300">
                    {faq.answer}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}