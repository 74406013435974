import { openaiConfig } from '../config/openai';
import { AnalysisResponse } from '../types';

function getMealType(hour: number): 'breakfast' | 'lunch' | 'dinner' | 'snack' {
  if (hour >= 5 && hour < 11) return 'breakfast';
  if (hour >= 11 && hour < 15) return 'lunch';
  if (hour >= 17 && hour < 22) return 'dinner';
  return 'snack';
}

export async function analyzeImage(imageBase64: string): Promise<AnalysisResponse> {
  try {
    const currentHour = new Date().getHours();
    const mealType = getMealType(currentHour);

    // First, check if the image contains food
    const foodCheckResponse = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: openaiConfig.headers,
      body: JSON.stringify({
        model: openaiConfig.models.default,
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: 'Is this a food image? Respond with only "yes" or "no".'
              },
              {
                type: 'image_url',
                image_url: {
                  url: imageBase64
                }
              }
            ]
          }
        ],
        max_tokens: 50
      }),
    });

    if (!foodCheckResponse.ok) {
      throw new Error('Failed to analyze image');
    }

    const foodCheckData = await foodCheckResponse.json();
    const foodCheckResult = foodCheckData.choices[0].message.content.toLowerCase();

    if (!foodCheckResult.includes('yes')) {
      throw new Error('Please provide a clear image of food');
    }

    // Continue with food analysis
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: openaiConfig.headers,
      body: JSON.stringify({
        model: openaiConfig.models.default,
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: `Analyze this food image and list the items, estimated calories, and nutritional information. 
                Format the response as a JSON object with these properties:
                {
                  "foods": string[],
                  "calories": number,
                  "nutrition": {
                    "protein": number,
                    "carbs": number,
                    "fats": number
                  },
                  "healthScore": number
                }`
              },
              {
                type: 'image_url',
                image_url: {
                  url: imageBase64
                }
              }
            ]
          }
        ],
        max_tokens: 1000,
        response_format: { type: "json_object" }
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to analyze image');
    }

    const data = await response.json();
    const result = JSON.parse(data.choices[0].message.content);

    return {
      ...result,
      mealType,
      timestamp: new Date().toISOString(),
      id: Date.now().toString()
    };
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to analyze image');
  }
}