import { openaiConfig } from '../config/openai';
import { MenuAnalysis } from '../types';
import { APIError } from '../utils/api';

export async function analyzeMenu(imageBase64: string): Promise<MenuAnalysis> {
  try {
    if (!openaiConfig.apiKey) {
      throw new APIError('OpenAI API key is not configured', 401);
    }

    const formattedImageUrl = imageBase64.startsWith('data:') 
      ? imageBase64 
      : `data:image/jpeg;base64,${imageBase64}`;

    // First, check if the image contains a menu
    const menuCheckResponse = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: openaiConfig.headers,
      body: JSON.stringify({
        model: openaiConfig.models.default,
        messages: [
          {
            role: 'user',
            content: [
              { 
                type: 'text', 
                text: 'Is this a restaurant menu? Respond with only "yes" or "no".' 
              },
              {
                type: 'image_url',
                image_url: { 
                  url: formattedImageUrl 
                }
              }
            ]
          }
        ],
        max_tokens: 10
      })
    });

    if (!menuCheckResponse.ok) {
      const errorData = await menuCheckResponse.json();
      throw new APIError(errorData.error?.message || 'Failed to analyze image', menuCheckResponse.status);
    }

    const menuCheckData = await menuCheckResponse.json();
    const menuCheckResult = menuCheckData.choices[0].message.content.toLowerCase();
    
    if (!menuCheckResult.includes('yes')) {
      throw new APIError('This image does not appear to be a restaurant menu. Please try again with a menu image.', 400);
    }

    // Continue with menu analysis
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: openaiConfig.headers,
      body: JSON.stringify({
        model: openaiConfig.models.default,
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: `Analyze this restaurant menu and provide detailed nutritional information. For each item, estimate calories, macronutrients, and assign a health score out of 10 (where 10 is extremely healthy and 1 is least healthy). Format the response as JSON:
                {
                  "items": [
                    {
                      "name": string,
                      "description": string,
                      "calories": number,
                      "nutrition": {
                        "protein": number,
                        "carbs": number,
                        "fats": number
                      },
                      "healthScore": number (1-10),
                      "dietaryTags": string[],
                      "allergens": string[]
                    }
                  ],
                  "healthiestOptions": string[],
                  "recommendations": {
                    "lowCalorie": string[],
                    "highProtein": string[],
                    "vegetarian": string[],
                    "balanced": string[]
                  },
                  "generalAdvice": string,
                  "restaurantType": string,
                  "cuisineType": string,
                  "dietaryWarnings": string[]
                }
                
                Important:
                - Provide realistic calorie estimates for each dish
                - Include protein, carbs, and fats in grams
                - Health scores should consider nutritional balance, not just calories
                - Consider portion sizes in estimates
                - Include specific allergens when identifiable`
              },
              {
                type: 'image_url',
                image_url: { url: formattedImageUrl }
              }
            ]
          }
        ],
        max_tokens: 4096,
        temperature: 0.7,
        response_format: { type: "json_object" }
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new APIError(errorData.error?.message || 'Failed to analyze menu', response.status);
    }

    const data = await response.json();
    const parsedContent = JSON.parse(data.choices[0].message.content);

    // Validate and sanitize the response
    return {
      items: parsedContent.items?.map((item: any) => ({
        name: item.name || 'Unknown Item',
        description: item.description || '',
        calories: Number(item.calories) || 0,
        nutrition: {
          protein: Number(item.nutrition?.protein) || 0,
          carbs: Number(item.nutrition?.carbs) || 0,
          fats: Number(item.nutrition?.fats) || 0
        },
        healthScore: Math.min(Math.max(Number(item.healthScore) || 5, 1), 10),
        dietaryTags: Array.isArray(item.dietaryTags) ? item.dietaryTags : [],
        allergens: Array.isArray(item.allergens) ? item.allergens : []
      })) || [],
      healthiestOptions: Array.isArray(parsedContent.healthiestOptions) ? parsedContent.healthiestOptions : [],
      recommendations: {
        lowCalorie: Array.isArray(parsedContent.recommendations?.lowCalorie) ? parsedContent.recommendations.lowCalorie : [],
        highProtein: Array.isArray(parsedContent.recommendations?.highProtein) ? parsedContent.recommendations.highProtein : [],
        vegetarian: Array.isArray(parsedContent.recommendations?.vegetarian) ? parsedContent.recommendations.vegetarian : [],
        balanced: Array.isArray(parsedContent.recommendations?.balanced) ? parsedContent.recommendations.balanced : []
      },
      generalAdvice: parsedContent.generalAdvice || 'No specific advice available.',
      restaurantType: parsedContent.restaurantType || 'Restaurant',
      cuisineType: parsedContent.cuisineType || 'Mixed Cuisine',
      dietaryWarnings: Array.isArray(parsedContent.dietaryWarnings) ? parsedContent.dietaryWarnings : []
    };
  } catch (error) {
    console.error('Menu analysis error:', error);
    if (error instanceof APIError) {
      throw error;
    }
    throw new APIError('Failed to analyze menu. Please try again.', 500);
  }
}