import React from 'react';
import { 
  ArrowLeft,
  MapPin,
  Clock,
  DollarSign,
  Star,
  AlertTriangle,
  Utensils,
  Heart,
  Info
} from 'lucide-react';
import type { RestaurantSearchResults } from '../../types';

interface RestaurantResultsProps {
  results: RestaurantSearchResults;
  isLoading: boolean;
  onBack: () => void;
}

export default function RestaurantResults({ results, isLoading, onBack }: RestaurantResultsProps) {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Header */}
      <div className="flex items-center justify-between">
        <button
          onClick={onBack}
          className="flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
        >
          <ArrowLeft className="w-5 h-5" />
          New Search
        </button>
      </div>

      {/* Healthy Recommendations */}
      {results.recommendations.healthyChoices.length > 0 && (
        <div className="bg-gradient-to-br from-green-500 to-emerald-600 rounded-xl p-6 text-white">
          <div className="flex items-center gap-3 mb-4">
            <Heart className="w-6 h-6" />
            <h3 className="text-lg font-semibold">Healthy Choices</h3>
          </div>
          <div className="grid gap-4">
            {results.recommendations.healthyChoices.map((choice, index) => (
              <div key={index} className="bg-white/10 rounded-xl p-4">
                <div className="font-medium">{choice}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Restaurant List */}
      <div className="space-y-6">
        {results.restaurants.map((restaurant, index) => (
          <div 
            key={index}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
          >
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                    {restaurant.name}
                  </h3>
                  <div className="flex items-center gap-4 text-sm text-gray-600 dark:text-gray-400">
                    <div className="flex items-center gap-1">
                      <Utensils className="w-4 h-4" />
                      {restaurant.cuisine}
                    </div>
                    <div className="flex items-center gap-1">
                      <DollarSign className="w-4 h-4" />
                      {restaurant.priceRange}
                    </div>
                    <div className="flex items-center gap-1">
                      <Star className="w-4 h-4 text-yellow-400" />
                      {restaurant.rating.toFixed(1)}
                    </div>
                  </div>
                </div>
                {restaurant.reservationRecommended && (
                  <span className="px-3 py-1 bg-red-100 dark:bg-red-900/20 text-red-700 dark:text-red-300 text-sm rounded-full">
                    Reservation Recommended
                  </span>
                )}
              </div>

              <div className="flex items-start gap-2 text-gray-600 dark:text-gray-400 mb-4">
                <MapPin className="w-4 h-4 mt-1 shrink-0" />
                <span>{restaurant.address}</span>
              </div>

              <p className="text-gray-600 dark:text-gray-300 mb-4">
                {restaurant.description}
              </p>

              {/* Busy Hours */}
              <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 mb-4">
                <Clock className="w-4 h-4" />
                <span>Busy Hours: {restaurant.busyHours}</span>
              </div>

              {/* Tags */}
              <div className="flex flex-wrap gap-2 mb-4">
                {restaurant.dietaryOptions.map((option, i) => (
                  <span 
                    key={i}
                    className="px-3 py-1 bg-blue-100 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300 text-sm rounded-full"
                  >
                    {option}
                  </span>
                ))}
              </div>

              {/* Best Dishes */}
              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
                <h4 className="font-medium text-gray-900 dark:text-white mb-2">
                  Must-Try Dishes
                </h4>
                <ul className="grid md:grid-cols-2 gap-2">
                  {restaurant.bestDishes.map((dish, i) => (
                    <li 
                      key={i}
                      className="flex items-center gap-2 text-gray-600 dark:text-gray-300"
                    >
                      <span className="w-1.5 h-1.5 bg-blue-500 rounded-full" />
                      {dish}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Dietary Warnings */}
      {results.recommendations.dietaryWarnings.length > 0 && (
        <div className="bg-amber-50 dark:bg-amber-900/20 rounded-xl p-6">
          <div className="flex items-center gap-3 mb-4">
            <AlertTriangle className="w-5 h-5 text-amber-500" />
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Important Notes
            </h3>
          </div>
          <ul className="space-y-2">
            {results.recommendations.dietaryWarnings.map((warning, index) => (
              <li 
                key={index}
                className="flex items-start gap-2 text-amber-700 dark:text-amber-300"
              >
                <span>•</span>
                {warning}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* General Advice */}
      <div className="bg-blue-50 dark:bg-blue-900/20 rounded-xl p-6">
        <div className="flex items-center gap-3 mb-4">
          <Info className="w-5 h-5 text-blue-500" />
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Tips & Advice
          </h3>
        </div>
        <p className="text-gray-700 dark:text-gray-300">
          {results.generalAdvice}
        </p>
      </div>
    </div>
  );
}