import { openaiConfig } from '../config/openai';

export interface Message {
  role: 'user' | 'assistant';
  content: string;
  timestamp: string;
  error?: boolean;
}

const SYSTEM_PROMPT = `You are a friendly AI nutritionist. Keep your responses concise and focused, typically one paragraph. Provide practical, actionable advice about nutrition and healthy eating. If you need to list items, keep it to 3 key points maximum.`;

export async function sendChatMessage(message: string, history: Message[]): Promise<string> {
  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: openaiConfig.headers,
      body: JSON.stringify({
        model: 'gpt-4o-mini',
        messages: [
          { role: 'system', content: SYSTEM_PROMPT },
          ...history.map(msg => ({
            role: msg.role,
            content: msg.content
          })),
          { role: 'user', content: message }
        ],
        temperature: 0.7,
        max_tokens: 150
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to get response from AI');
    }

    const data = await response.json();
    return data.choices[0].message.content;
  } catch (error) {
    console.error('Chat error:', error);
    throw error;
  }
}