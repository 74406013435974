import React from 'react';
import { Zap, Gift, Sparkles } from 'lucide-react';

export default function Pricing() {
  return (
    <section id="pricing" className="py-20 bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Special Beta Access
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Experience all premium features completely free
          </p>
        </div>

        <div className="max-w-3xl mx-auto">
          <div className="bg-gradient-to-br from-blue-500 to-indigo-600 rounded-2xl p-8 text-white text-center">
            <div className="flex justify-center mb-6">
              <div className="p-3 bg-white/10 rounded-full">
                <Gift className="w-8 h-8" />
              </div>
            </div>

            <h3 className="text-2xl font-bold mb-4">
              🎉 Free Beta Access
            </h3>

            <p className="text-lg text-blue-100 mb-6">
              We're currently in beta, and all premium features are available to everyone at no cost!
            </p>

            <div className="grid md:grid-cols-3 gap-6 mb-8">
              <div className="bg-white/10 rounded-xl p-4">
                <Zap className="w-6 h-6 mx-auto mb-2" />
                <p className="font-medium">Unlimited Food Analysis</p>
              </div>
              <div className="bg-white/10 rounded-xl p-4">
                <Sparkles className="w-6 h-6 mx-auto mb-2" />
                <p className="font-medium">Menu Analysis</p>
              </div>
              <div className="bg-white/10 rounded-xl p-4">
                <Gift className="w-6 h-6 mx-auto mb-2" />
                <p className="font-medium">AI Nutrition Chat</p>
              </div>
            </div>

            <p className="text-sm text-blue-100 max-w-2xl mx-auto">
              After the beta period ends, we'll introduce our regular pricing plans. Early beta users may receive special benefits - stay tuned!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}