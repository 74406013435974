export class APIError extends Error {
  constructor(
    message: string,
    public status: number = 500,
    public code?: string
  ) {
    super(message);
    this.name = 'APIError';
  }
}

export async function fetchWithTimeout(
  resource: RequestInfo,
  options: RequestInit & { timeout?: number } = {}
) {
  const { timeout = 30000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    });
    clearTimeout(id);
    return response;
  } catch (error) {
    clearTimeout(id);
    if (error instanceof Error) {
      if (error.name === 'AbortError') {
        throw new APIError('Request timed out', 408);
      }
    }
    throw error;
  }
}

export async function handleAPIResponse(response: Response) {
  if (!response.ok) {
    const error = await response.json().catch(() => ({}));
    throw new APIError(
      error.error?.message || 'An error occurred',
      response.status,
      error.error?.code
    );
  }
  return response.json();
}

export function retryWithBackoff(
  fn: () => Promise<any>,
  maxRetries: number = 3,
  baseDelay: number = 1000
): Promise<any> {
  return fn().catch((error) => {
    if (maxRetries === 0) throw error;
    
    // Calculate delay with exponential backoff
    const delay = baseDelay * Math.pow(2, 3 - maxRetries);
    
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(retryWithBackoff(fn, maxRetries - 1, baseDelay));
      }, delay);
    });
  });
}