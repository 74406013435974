import React from 'react';
import { Mail, Heart } from 'lucide-react';
import Logo from './Logo';

interface FooterProps {
  onPageChange: (page: string) => void;
}

export default function Footer({ onPageChange }: FooterProps) {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 text-gray-300">
      {/* Main Footer Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Company Info */}
          <div>
            <Logo className="mb-4" />
            <p className="text-gray-400">
              AI-powered nutrition tracking for a healthier lifestyle. Analyze your meals instantly and get personalized recommendations.
            </p>
          </div>

          {/* Legal */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Legal</h4>
            <ul className="space-y-2">
              <li>
                <button 
                  onClick={() => onPageChange('privacy')}
                  className="hover:text-white transition-colors"
                >
                  Privacy Policy
                </button>
              </li>
              <li>
                <button 
                  onClick={() => onPageChange('terms')}
                  className="hover:text-white transition-colors"
                >
                  Terms of Service
                </button>
              </li>
              <li>
                <button 
                  onClick={() => onPageChange('refund')}
                  className="hover:text-white transition-colors"
                >
                  Refund Policy
                </button>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Contact Us</h4>
            <ul className="space-y-4">
              <li className="flex items-center gap-2">
                <Mail size={18} />
                <a href="mailto:contact@calofood.com" className="hover:text-white transition-colors">
                  contact@calofood.com
                </a>
              </li>
              <li className="flex items-center gap-2 text-gray-400">
                <Heart size={18} className="text-red-500" />
                <span>Made with love in Marrakech</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="text-center">
            <p>© {currentYear} Calofood. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}