import React from 'react';
import { MenuAnalysis } from '../../types';
import { 
  ArrowLeft, 
  Utensils, 
  Heart, 
  AlertTriangle,
  Leaf,
  Award,
  Scale,
  Info,
  Beef,
  Cookie,
  Droplets
} from 'lucide-react';

interface MenuResultsProps {
  result: MenuAnalysis | null;
  isLoading: boolean;
  onBack: () => void;
}

export default function MenuResults({ result, isLoading, onBack }: MenuResultsProps) {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!result?.items?.length) {
    return (
      <div className="text-center py-12">
        <div className="text-gray-500 dark:text-gray-400">
          No menu items found. Please try again with a clearer image.
        </div>
        <button
          onClick={onBack}
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header with back button */}
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4">
          <button
            onClick={onBack}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors"
          >
            <ArrowLeft className="w-6 h-6 text-gray-900 dark:text-white" />
          </button>
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Menu Analysis</h2>
        </div>
        <div className="flex items-center gap-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white px-4 py-2 rounded-full">
          <Utensils className="w-4 h-4" />
          <span className="font-medium">{result.restaurantType}</span>
        </div>
      </div>

      {/* Healthiest Options Card */}
      <div className="bg-gradient-to-br from-green-500 to-emerald-600 rounded-2xl p-6 text-white">
        <div className="flex items-center gap-3 mb-4">
          <Heart className="w-6 h-6" />
          <h3 className="text-lg font-semibold">Healthiest Choices</h3>
        </div>
        <div className="grid gap-4">
          {result.healthiestOptions.map((item, index) => (
            <div key={index} className="bg-white/10 rounded-xl p-4">
              <div className="font-medium">{item}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Recommendations Grid */}
      <div className="grid md:grid-cols-2 gap-4">
        {/* Low Calorie Options */}
        <div className="bg-gradient-to-br from-blue-500 to-cyan-600 rounded-2xl p-6 text-white">
          <div className="flex items-center gap-3 mb-4">
            <Scale className="w-6 h-6" />
            <h3 className="text-lg font-semibold">Low Calorie Options</h3>
          </div>
          <ul className="space-y-2">
            {result.recommendations.lowCalorie.map((item, index) => (
              <li key={index} className="flex items-start gap-2">
                <span>•</span>
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Vegetarian Options */}
        <div className="bg-gradient-to-br from-emerald-500 to-green-600 rounded-2xl p-6 text-white">
          <div className="flex items-center gap-3 mb-4">
            <Leaf className="w-6 h-6" />
            <h3 className="text-lg font-semibold">Vegetarian Options</h3>
          </div>
          <ul className="space-y-2">
            {result.recommendations.vegetarian.map((item, index) => (
              <li key={index} className="flex items-start gap-2">
                <span>•</span>
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Menu Items Analysis */}
      <div className="space-y-4">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
          Detailed Menu Analysis
        </h3>
        <div className="grid gap-4">
          {result.items.map((item, index) => (
            <div 
              key={index}
              className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white">
                    {item.name}
                  </h4>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                    {item.description}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <Award className={`w-5 h-5 ${
                    item.healthScore >= 7 ? 'text-green-500' :
                    item.healthScore >= 4 ? 'text-yellow-500' : 'text-red-500'
                  }`} />
                  <span className="font-medium">{item.healthScore}/10</span>
                </div>
              </div>

              {/* Nutrition Grid */}
              <div className="grid grid-cols-4 gap-4 mb-4">
                <div className="space-y-1">
                  <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
                    <Scale className="w-4 h-4" />
                    <span className="text-sm">Calories</span>
                  </div>
                  <div className="font-medium text-gray-900 dark:text-white">
                    {item.calories}
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
                    <Beef className="w-4 h-4" />
                    <span className="text-sm">Protein</span>
                  </div>
                  <div className="font-medium text-gray-900 dark:text-white">
                    {item.nutrition.protein}g
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
                    <Cookie className="w-4 h-4" />
                    <span className="text-sm">Carbs</span>
                  </div>
                  <div className="font-medium text-gray-900 dark:text-white">
                    {item.nutrition.carbs}g
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
                    <Droplets className="w-4 h-4" />
                    <span className="text-sm">Fats</span>
                  </div>
                  <div className="font-medium text-gray-900 dark:text-white">
                    {item.nutrition.fats}g
                  </div>
                </div>
              </div>

              {/* Tags and Allergens */}
              <div className="space-y-3">
                {item.dietaryTags.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {item.dietaryTags.map((tag, tagIndex) => (
                      <span 
                        key={tagIndex}
                        className="px-2 py-1 bg-blue-100 dark:bg-blue-900/20 text-blue-800 dark:text-blue-200 text-sm rounded-full"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                )}

                {item.allergens.length > 0 && (
                  <div className="flex items-start gap-2 text-sm text-amber-600 dark:text-amber-400">
                    <AlertTriangle className="w-4 h-4 mt-0.5" />
                    <span>Contains: {item.allergens.join(', ')}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Dietary Warnings */}
      {result.dietaryWarnings.length > 0 && (
        <div className="bg-amber-50 dark:bg-amber-900/20 rounded-2xl p-6">
          <div className="flex items-center gap-3 mb-4">
            <AlertTriangle className="w-6 h-6 text-amber-500" />
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Important Notes
            </h3>
          </div>
          <ul className="space-y-2">
            {result.dietaryWarnings.map((warning, index) => (
              <li key={index} className="flex items-start gap-2 text-amber-700 dark:text-amber-300">
                <span>•</span>
                {warning}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Expert Tips */}
      <div className="bg-gradient-to-br from-purple-500 to-indigo-600 rounded-2xl p-6 text-white">
        <div className="flex items-center gap-3 mb-4">
          <Info className="w-6 h-6" />
          <h3 className="text-lg font-semibold">Expert Tips</h3>
        </div>
        <p className="leading-relaxed">{result.generalAdvice}</p>
      </div>
    </div>
  );
}