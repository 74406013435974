import React from 'react';
import { Calendar, Clock, ArrowRight } from 'lucide-react';
import { BlogPost } from '../../types';

interface BlogProps {
  articles?: BlogPost[];
  onArticleClick?: (article: BlogPost) => void;
}

const SAMPLE_ARTICLES: BlogPost[] = [
  {
    id: '1',
    title: 'Understanding Calories: A Complete Guide',
    description: 'Learn everything you need to know about calories and how they affect your body.',
    content: '<p>Detailed content about calories...</p>',
    image: 'https://images.unsplash.com/photo-1490645935967-10de6ba17061?auto=format&fit=crop&q=80&w=800',
    date: '2024-03-15',
    readTime: '8 min read',
    slug: 'understanding-calories-guide',
    category: 'Nutrition'
  },
  {
    id: '2',
    title: 'The Science Behind Food Photography',
    description: 'Discover how AI analyzes food images for accurate nutritional information.',
    content: '<p>Detailed content about food photography...</p>',
    image: 'https://images.unsplash.com/photo-1482049016688-2d3e1b311543?auto=format&fit=crop&q=80&w=800',
    date: '2024-03-10',
    readTime: '5 min read',
    slug: 'science-food-photography',
    category: 'Technology'
  },
  {
    id: '3',
    title: 'Healthy Eating Made Simple',
    description: 'Simple strategies for maintaining a balanced and nutritious diet.',
    content: '<p>Detailed content about healthy eating...</p>',
    image: 'https://images.unsplash.com/photo-1498837167922-ddd27525d352?auto=format&fit=crop&q=80&w=800',
    date: '2024-03-05',
    readTime: '6 min read',
    slug: 'healthy-eating-simple',
    category: 'Wellness'
  }
];

export default function Blog({ articles = SAMPLE_ARTICLES, onArticleClick }: BlogProps) {
  const handleArticleClick = (article: BlogPost) => {
    if (onArticleClick) {
      onArticleClick(article);
    }
  };

  return (
    <section className="py-20 bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Latest from Our Blog
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Expert insights on nutrition, health, and food tracking
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {articles.map((article) => (
            <article 
              key={article.id}
              className="bg-gray-50 dark:bg-gray-700 rounded-xl overflow-hidden hover:shadow-lg transition-shadow cursor-pointer"
              onClick={() => handleArticleClick(article)}
            >
              <img
                src={article.image}
                alt={article.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <div className="flex items-center gap-4 mb-4 text-sm text-gray-600 dark:text-gray-400">
                  <span className="inline-flex items-center gap-1">
                    <Calendar size={16} />
                    {new Date(article.date).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric'
                    })}
                  </span>
                  <span className="inline-flex items-center gap-1">
                    <Clock size={16} />
                    {article.readTime}
                  </span>
                </div>
                <span className="inline-block px-3 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 text-sm rounded-full mb-3">
                  {article.category}
                </span>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                  {article.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  {article.description}
                </p>
                <div className="inline-flex items-center gap-2 text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 transition-colors">
                  Read More
                  <ArrowRight size={16} />
                </div>
              </div>
            </article>
          ))}
        </div>

        <div className="text-center mt-12">
          <button className="inline-flex items-center gap-2 bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition-colors">
            View All Articles
            <ArrowRight size={20} />
          </button>
        </div>
      </div>
    </section>
  );
}