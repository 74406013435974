import React from 'react';
import { Utensils } from 'lucide-react';

interface LogoProps {
  className?: string;
}

export default function Logo({ className = '' }: LogoProps) {
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <div className="p-1.5 bg-blue-600 rounded-lg">
        <Utensils className="w-6 h-6 text-white" />
      </div>
      <span className="text-xl font-bold text-gray-900 dark:text-white">
        Calofood
      </span>
    </div>
  );
}