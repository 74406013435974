import React from 'react';
import { Camera, Brain, Lightbulb } from 'lucide-react';

const steps = [
  {
    icon: Camera,
    title: 'Snap or Upload a Photo',
    description: 'Take a quick photo of your meal or upload an image',
  },
  {
    icon: Brain,
    title: 'Analyze Nutrients',
    description: 'Our AI scans the food and provides detailed nutritional information',
  },
  {
    icon: Lightbulb,
    title: 'Get Personalized Tips',
    description: 'Receive insights, meal suggestions, and advice tailored to your fitness goals',
  },
];

export default function HowItWorks() {
  return (
    <section className="py-20 bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            How It Works
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Get started with three simple steps
          </p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <div 
              key={index}
              className="relative bg-white dark:bg-gray-800 p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="absolute -top-6 left-1/2 -translate-x-1/2">
                <div className="w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center text-white">
                  <step.icon className="w-6 h-6" />
                </div>
              </div>
              <div className="mt-6 text-center">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                  {step.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  {step.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}