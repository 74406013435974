import React from 'react';
import { Star, Scan, MessageSquare, ArrowRight, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const testimonials = [
  {
    name: 'Claire Thompson',
    role: 'Fitness Enthusiast',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&q=80&w=150&h=150',
    quote: 'This app has completely transformed how I track my nutrition. The AI analysis is incredibly accurate!'
  },
  {
    name: 'Alex Moreno',
    role: 'Personal Trainer',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80&w=150&h=150',
    quote: 'I recommend this to all my clients. It makes nutrition tracking effortless and actually fun.'
  },
  {
    name: 'Nina Roberts',
    role: 'Health Coach',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80&w=150&h=150',
    quote: 'The personalized recommendations are spot-on. It\'s like having a nutritionist in your pocket.'
  }
];

const otherTools = [
  {
    title: 'Restaurant Menu Analyzer',
    description: 'Get instant healthy recommendations from any restaurant menu. Make smarter dining choices with AI-powered menu analysis.',
    icon: Scan,
    image: 'https://images.unsplash.com/photo-1414235077428-338989a2e8c0?auto=format&fit=crop&q=80&w=800',
    path: '/menu-analyzer',
    gradient: 'from-emerald-500 to-teal-600'
  },
  {
    title: 'AI Nutrition Chat',
    description: 'Chat with our AI nutritionist for personalized advice, meal planning tips, and answers to all your nutrition questions.',
    icon: MessageSquare,
    image: 'https://images.unsplash.com/photo-1550989460-0adf9ea622e2?auto=format&fit=crop&q=80&w=800',
    path: '/chat',
    gradient: 'from-blue-500 to-indigo-600'
  },
  {
    title: 'AI Meal Planner',
    description: 'Get personalized meal plans tailored to your dietary preferences, goals, and restrictions. Let AI plan your perfect menu.',
    icon: Calendar,
    image: 'https://images.unsplash.com/photo-1543352634-99a5d50ae78e?auto=format&fit=crop&q=80&w=800',
    path: '/meal-planner',
    gradient: 'from-purple-500 to-pink-600'
  }
];

export default function Testimonials() {
  const navigate = useNavigate();

  return (
    <>
      <section className="py-20 bg-gray-50 dark:bg-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
              What Our Users Say
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Join thousands of satisfied users on their health journey
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div 
                key={index}
                className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg"
              >
                <div className="flex items-center gap-4 mb-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-16 h-16 rounded-full object-cover"
                  />
                  <div>
                    <h3 className="font-semibold text-gray-900 dark:text-white">
                      {testimonial.name}
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {testimonial.role}
                    </p>
                  </div>
                </div>
                <div className="flex gap-1 mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                  ))}
                </div>
                <p className="text-gray-600 dark:text-gray-300 italic">
                  "{testimonial.quote}"
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Discover Also Section */}
      <section className="py-20 bg-white dark:bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
              Discover Also
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Explore our complete suite of AI-powered nutrition tools
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {otherTools.map((tool, index) => (
              <div 
                key={index}
                className="group relative overflow-hidden rounded-2xl"
              >
                {/* Background Image with Gradient Overlay */}
                <div className="absolute inset-0">
                  <img
                    src={tool.image}
                    alt={tool.title}
                    className="w-full h-full object-cover"
                  />
                  <div className={`absolute inset-0 bg-gradient-to-r ${tool.gradient} opacity-90`}></div>
                </div>

                {/* Content */}
                <div className="relative p-8 text-white h-full flex flex-col justify-between min-h-[320px]">
                  <div>
                    <div className="flex items-center gap-3 mb-4">
                      <tool.icon className="w-8 h-8" />
                      <h3 className="text-2xl font-bold">{tool.title}</h3>
                    </div>
                    <p className="text-lg text-white/90 mb-6">
                      {tool.description}
                    </p>
                  </div>

                  <button
                    onClick={() => navigate(tool.path)}
                    className="inline-flex items-center gap-2 bg-white text-gray-900 px-6 py-3 rounded-full font-medium hover:bg-gray-100 transition-colors group"
                  >
                    Try Now
                    <ArrowRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}