import React from 'react';
import { Helmet } from 'react-helmet-async';
import ChatInterface from '../components/chat/ChatInterface';

export default function ChatPage() {
  return (
    <>
      <Helmet>
        <title>AI Nutrition Expert Chat | Get Personalized Diet Advice | Calofood</title>
        <meta 
          name="description" 
          content="Chat with our AI nutrition expert for personalized diet advice, meal planning tips, and answers to all your nutrition questions. Available 24/7." 
        />
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            AI Nutrition Expert
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Get personalized nutrition advice and answers to your health questions
          </p>
        </div>
        <ChatInterface />
      </div>
    </>
  );
}