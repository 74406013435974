import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Camera from '../components/Camera';
import MenuResults from '../components/menu/MenuResults';
import { MenuAnalysis } from '../types';
import { analyzeMenu } from '../services/menuAnalysis';

export default function MenuAnalyzerPage() {
  const [result, setResult] = useState<MenuAnalysis | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const handleAnalyze = async (imageData: string) => {
    setIsAnalyzing(true);
    setError(null);
    
    try {
      const analysis = await analyzeMenu(imageData);
      setResult(analysis);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to analyze menu');
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleBack = () => {
    setResult(null);
    setError(null);
  };

  return (
    <>
      <Helmet>
        <title>Restaurant Menu Analyzer | Healthy Menu Options | Calofood</title>
        <meta 
          name="description" 
          content="Make healthier choices when dining out. Our AI menu analyzer helps you find the best nutritional options at any restaurant. Get personalized menu recommendations." 
        />
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Restaurant Menu Analyzer
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Take a photo of any restaurant menu to get instant healthy recommendations
          </p>
        </div>

        <div className="max-w-4xl mx-auto">
          {error && (
            <div className="mb-8 p-4 bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-300 rounded-xl">
              {error}
            </div>
          )}

          {result ? (
            <MenuResults
              result={result}
              isLoading={isAnalyzing}
              onBack={handleBack}
            />
          ) : (
            <div className="max-w-md mx-auto">
              <Camera
                mode="menu"
                onResult={handleAnalyze}
                onError={setError}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}