import { SpeechRecognitionResult } from '../types';

class VoiceService {
  private recognition: SpeechRecognition | null = null;
  private synthesis: SpeechSynthesis;
  private preferredVoice: SpeechSynthesisVoice | null = null;

  constructor() {
    const SpeechRecognitionImpl = window.SpeechRecognition || window.webkitSpeechRecognition;
    
    if (SpeechRecognitionImpl) {
      this.recognition = new SpeechRecognitionImpl();
      this.setupRecognition();
    }
    
    this.synthesis = window.speechSynthesis;
    this.loadVoices();
  }

  private setupRecognition() {
    if (!this.recognition) return;

    this.recognition.continuous = false;
    this.recognition.interimResults = true;
    this.recognition.lang = 'en-US';
  }

  private loadVoices() {
    const voices = this.synthesis.getVoices();
    this.setPreferredVoice(voices);

    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = () => {
        const updatedVoices = this.synthesis.getVoices();
        this.setPreferredVoice(updatedVoices);
      };
    }
  }

  private setPreferredVoice(voices: SpeechSynthesisVoice[]) {
    // Priority list of preferred voices
    const preferredVoiceNames = [
      'Google UK English Female', // Most natural-sounding Google voice
      'Microsoft Libby Online (Natural)', // Natural Microsoft voice
      'Karen', // Australian female voice (very natural)
      'Daniel', // British male voice (natural)
      'Samantha', // US female voice
      'Google US English Female'
    ];

    // Try to find one of our preferred voices
    for (const voiceName of preferredVoiceNames) {
      const voice = voices.find(v => v.name.includes(voiceName));
      if (voice) {
        this.preferredVoice = voice;
        return;
      }
    }

    // Fallback to any English voice if preferred ones aren't available
    this.preferredVoice = voices.find(voice => 
      voice.lang.startsWith('en-') && !voice.name.includes('Google US English Male')
    ) || voices[0];
  }

  startListening(
    onInterim: (text: string) => void,
    onResult: (result: SpeechRecognitionResult) => void,
    onError: (error: string) => void
  ) {
    if (!this.recognition) {
      onError('Speech recognition is not supported in your browser.');
      return;
    }

    this.recognition.onresult = (event: SpeechRecognitionEvent) => {
      const transcript = event.results[0][0].transcript;
      
      if (event.results[0].isFinal) {
        onResult({ 
          transcript, 
          confidence: event.results[0][0].confidence 
        });
      } else {
        onInterim(transcript);
      }
    };

    this.recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
      onError(event.error);
    };

    this.recognition.start();
  }

  stopListening() {
    if (this.recognition) {
      this.recognition.stop();
    }
  }

  speak(text: string, onStart?: () => void, onEnd?: () => void) {
    this.synthesis.cancel();

    const utterance = new SpeechSynthesisUtterance(text);
    if (this.preferredVoice) {
      utterance.voice = this.preferredVoice;
    }

    // Adjusted parameters for more natural speech
    utterance.rate = 0.9;    // Slightly slower for better clarity
    utterance.pitch = 1.0;   // Natural pitch
    utterance.volume = 1;

    // Add slight pauses at punctuation for more natural rhythm
    text = text.replace(/([.,!?])/g, '$1 ');

    if (onStart) utterance.onstart = onStart;
    if (onEnd) utterance.onend = onEnd;

    this.synthesis.speak(utterance);
  }

  stopSpeaking() {
    this.synthesis.cancel();
  }
}

export const voiceService = new VoiceService();