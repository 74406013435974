import React, { useState, useRef, useEffect } from 'react';
import { Send, Bot, Mic, MicOff, Volume2, VolumeX, Loader2, X, RefreshCw } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import ChatMessage from './ChatMessage';
import ChatSuggestions from './ChatSuggestions';
import { Message, sendChatMessage } from '../../services/chatService';
import { voiceService } from '../../services/voiceService';

export default function ChatInterface() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [voiceEnabled, setVoiceEnabled] = useState(true);
  const [interimTranscript, setInterimTranscript] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [input]);

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!input.trim() || isLoading) return;

    await processMessage(input);
    setInput('');
  };

  const processMessage = async (text: string) => {
    const userMessage: Message = {
      role: 'user',
      content: text,
      timestamp: new Date().toISOString(),
    };

    setMessages(prev => [...prev, userMessage]);
    setIsLoading(true);

    try {
      const response = await sendChatMessage(text, messages);
      const assistantMessage: Message = {
        role: 'assistant',
        content: response,
        timestamp: new Date().toISOString(),
      };
      setMessages(prev => [...prev, assistantMessage]);
    } catch (error) {
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: 'I apologize, but I encountered an error. Please try again.',
        timestamp: new Date().toISOString(),
        error: true,
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const startListening = () => {
    setIsListening(true);
    voiceService.startListening(
      (interim) => setInterimTranscript(interim),
      (result) => {
        setIsListening(false);
        setInterimTranscript('');
        processMessage(result.transcript);
      },
      (error) => {
        console.error('Speech recognition error:', error);
        setIsListening(false);
        setInterimTranscript('');
      }
    );
  };

  const stopListening = () => {
    setIsListening(false);
    setInterimTranscript('');
    voiceService.stopListening();
  };

  const toggleVoice = () => {
    if (voiceEnabled) {
      stopListening();
      voiceService.stopSpeaking();
      setIsSpeaking(false);
    }
    setVoiceEnabled(!voiceEnabled);
  };

  const startNewChat = () => {
    if (isSpeaking) {
      voiceService.stopSpeaking();
    }
    if (isListening) {
      stopListening();
    }
    setMessages([]);
    setInput('');
    setInterimTranscript('');
    setIsLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden flex flex-col">
        <div className="border-b border-gray-200 dark:border-gray-700 p-4 flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Bot className="w-6 h-6 text-blue-500" />
            <h2 className="font-semibold text-gray-900 dark:text-white">
              AI Nutrition Expert
            </h2>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={startNewChat}
              className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-300"
              title="Start new chat"
            >
              <RefreshCw className="w-5 h-5" />
            </button>
            <button
              onClick={toggleVoice}
              className={`p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 ${
                voiceEnabled ? 'text-blue-500' : 'text-gray-400'
              }`}
              title={voiceEnabled ? 'Disable voice input' : 'Enable voice input'}
            >
              {voiceEnabled ? <Mic className="w-5 h-5" /> : <MicOff className="w-5 h-5" />}
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          {messages.length === 0 && (
            <div className="text-center space-y-4">
              <Bot className="w-16 h-16 mx-auto text-blue-500" />
              <p className="text-gray-600 dark:text-gray-300">
                Ask me anything about nutrition! You can type or use voice input.
              </p>
              <ChatSuggestions onSuggestionClick={setInput} />
            </div>
          )}

          {messages.map((message, index) => (
            <ChatMessage 
              key={index} 
              message={message}
              onPlay={(text) => voiceService.speak(text, () => setIsSpeaking(true), () => setIsSpeaking(false))}
              onStop={() => {
                voiceService.stopSpeaking();
                setIsSpeaking(false);
              }}
              isSpeaking={isSpeaking}
              voiceEnabled={true}
            />
          ))}

          {isLoading && (
            <div className="flex items-center gap-3 text-gray-600 dark:text-gray-300">
              <Loader2 className="w-5 h-5 animate-spin" />
              Thinking...
            </div>
          )}

          <div ref={messagesEndRef} />
        </div>

        <form onSubmit={handleSubmit} className="border-t border-gray-200 dark:border-gray-700 p-4">
          <div className="flex gap-4">
            <div className="flex-1 min-h-[20px] relative">
              <textarea
                ref={inputRef}
                rows={1}
                value={isListening ? interimTranscript : input}
                onChange={(e) => !isListening && setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Type or speak your message..."
                className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-gray-900 dark:text-white focus:border-blue-500 focus:ring-blue-500 resize-none"
                disabled={isLoading || isListening}
              />
              {voiceEnabled && (
                <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center gap-2">
                  {isListening && (
                    <button
                      type="button"
                      onClick={stopListening}
                      className="p-1.5 rounded-lg text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={isListening ? stopListening : startListening}
                    disabled={isLoading || isSpeaking}
                    className={`p-1.5 rounded-lg ${
                      isListening 
                        ? 'bg-red-100 text-red-500 dark:bg-red-900/20' 
                        : 'hover:bg-gray-100 dark:hover:bg-gray-600'
                    } ${(isLoading || isSpeaking) ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    {isListening ? (
                      <MicOff className="w-4 h-4" />
                    ) : (
                      <Mic className="w-4 h-4" />
                    )}
                  </button>
                </div>
              )}
            </div>
            <button
              type="submit"
              disabled={isLoading || (!input.trim() && !interimTranscript)}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Send className="w-5 h-5" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}