import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AlertTriangle } from 'lucide-react';
import { searchRestaurants } from '../services/restaurantService';
import RestaurantResults from '../components/restaurant/RestaurantResults';
import PreferenceForm from '../components/restaurant/PreferenceForm';
import type { RestaurantSearchResults } from '../types';

export default function RestaurantFinderPage() {
  const [results, setResults] = useState<RestaurantSearchResults | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSearch = async (preferences: any) => {
    setIsLoading(true);
    setError(null);
    
    try {
      const searchResults = await searchRestaurants(preferences);
      setResults(searchResults);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to search restaurants');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setResults(null);
    setError(null);
  };

  return (
    <>
      <Helmet>
        <title>AI Restaurant Finder | Find Healthy Restaurants Near You | Calofood</title>
        <meta 
          name="description" 
          content="Discover restaurants that match your dietary preferences and health goals. Get AI-powered recommendations for healthy dining options in your area." 
        />
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            AI Restaurant Finder
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Find the perfect restaurant based on your preferences and dietary needs
          </p>
        </div>

        <div className="max-w-4xl mx-auto">
          {error && (
            <div className="mb-8 p-4 bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-300 rounded-xl flex items-center gap-3">
              <AlertTriangle className="w-5 h-5 shrink-0" />
              <p>{error}</p>
            </div>
          )}

          {results ? (
            <RestaurantResults
              results={results}
              isLoading={isLoading}
              onBack={handleBack}
            />
          ) : (
            <PreferenceForm 
              onSubmit={handleSearch}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </>
  );
}