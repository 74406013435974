import React, { useState } from 'react';
import { Utensils, Scale, Activity, Clock, Heart, Apple } from 'lucide-react';

interface MealPlanFormProps {
  onSubmit: (preferences: any) => void;
  isLoading: boolean;
}

export default function MealPlanForm({ onSubmit, isLoading }: MealPlanFormProps) {
  const [preferences, setPreferences] = useState({
    calories: 2000,
    dietType: 'balanced',
    restrictions: [] as string[],
    meals: 3,
    activityLevel: 'moderate',
    mealTiming: [] as string[],
    preferences: [] as string[],
    excludeIngredients: [] as string[],
    cookingTime: 'any'
  });

  const dietTypes = [
    { id: 'balanced', name: 'Balanced' },
    { id: 'low-carb', name: 'Low Carb' },
    { id: 'high-protein', name: 'High Protein' },
    { id: 'keto', name: 'Ketogenic' },
    { id: 'mediterranean', name: 'Mediterranean' },
    { id: 'paleo', name: 'Paleo' },
    { id: 'vegetarian', name: 'Vegetarian' },
    { id: 'vegan', name: 'Vegan' },
    { id: 'pescatarian', name: 'Pescatarian' }
  ];

  const restrictions = [
    'Gluten-free',
    'Dairy-free',
    'Nut-free',
    'Soy-free',
    'Egg-free',
    'Shellfish-free',
    'Low-sodium',
    'Low-sugar',
    'Low-fat',
    'FODMAP-friendly'
  ];

  const mealTimings = [
    'Early breakfast (6-8 AM)',
    'Late breakfast (8-10 AM)',
    'Early lunch (11-1 PM)',
    'Late lunch (1-3 PM)',
    'Early dinner (5-7 PM)',
    'Late dinner (7-9 PM)',
    'Morning snack',
    'Afternoon snack',
    'Evening snack'
  ];

  const foodPreferences = [
    'Prefer whole foods',
    'Minimal processed foods',
    'High fiber',
    'Low glycemic',
    'Organic preferred',
    'Budget-friendly',
    'Quick prep meals',
    'Batch cooking friendly',
    'Kid-friendly',
    'Spicy food lover'
  ];

  const activityLevels = [
    { id: 'sedentary', name: 'Sedentary (little or no exercise)' },
    { id: 'light', name: 'Lightly active (1-3 days/week)' },
    { id: 'moderate', name: 'Moderately active (3-5 days/week)' },
    { id: 'very', name: 'Very active (6-7 days/week)' },
    { id: 'extra', name: 'Extra active (athletes, physical jobs)' }
  ];

  const cookingTimes = [
    { id: 'any', name: 'Any duration' },
    { id: 'quick', name: 'Quick (under 30 mins)' },
    { id: 'medium', name: 'Medium (30-60 mins)' },
    { id: 'long', name: 'Long (over 60 mins)' }
  ];

  const toggleItem = (item: string, category: 'restrictions' | 'mealTiming' | 'preferences') => {
    setPreferences(prev => ({
      ...prev,
      [category]: prev[category].includes(item)
        ? prev[category].filter(i => i !== item)
        : [...prev[category], item]
    }));
  };

  const handleExcludeIngredients = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const ingredients = e.target.value.split(',').map(i => i.trim()).filter(Boolean);
    setPreferences(prev => ({
      ...prev,
      excludeIngredients: ingredients
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(preferences);
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-4xl mx-auto space-y-8">
      {/* Daily Calories */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          <div className="flex items-center gap-2">
            <Scale className="w-4 h-4" />
            Daily Calories
          </div>
        </label>
        <input
          type="number"
          min="1200"
          max="5000"
          step="50"
          value={preferences.calories}
          onChange={e => setPreferences(prev => ({ ...prev, calories: Number(e.target.value) }))}
          className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-gray-900 dark:text-white"
        />
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          Recommended range: 1200-5000 calories
        </p>
      </div>

      {/* Activity Level */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          <div className="flex items-center gap-2">
            <Activity className="w-4 h-4" />
            Activity Level
          </div>
        </label>
        <select
          value={preferences.activityLevel}
          onChange={e => setPreferences(prev => ({ ...prev, activityLevel: e.target.value }))}
          className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-gray-900 dark:text-white"
        >
          {activityLevels.map(level => (
            <option key={level.id} value={level.id}>
              {level.name}
            </option>
          ))}
        </select>
      </div>

      {/* Diet Type */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          <div className="flex items-center gap-2">
            <Utensils className="w-4 h-4" />
            Diet Type
          </div>
        </label>
        <select
          value={preferences.dietType}
          onChange={e => setPreferences(prev => ({ ...prev, dietType: e.target.value }))}
          className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-gray-900 dark:text-white"
        >
          {dietTypes.map(diet => (
            <option key={diet.id} value={diet.id}>
              {diet.name}
            </option>
          ))}
        </select>
      </div>

      {/* Number of Meals */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          <div className="flex items-center gap-2">
            <Apple className="w-4 h-4" />
            Number of Meals
          </div>
        </label>
        <select
          value={preferences.meals}
          onChange={e => setPreferences(prev => ({ ...prev, meals: Number(e.target.value) }))}
          className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-gray-900 dark:text-white"
        >
          <option value={3}>3 meals</option>
          <option value={4}>4 meals (with snack)</option>
          <option value={5}>5 meals (with 2 snacks)</option>
          <option value={6}>6 meals (with 3 snacks)</option>
        </select>
      </div>

      {/* Cooking Time */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          <div className="flex items-center gap-2">
            <Clock className="w-4 h-4" />
            Preferred Cooking Time
          </div>
        </label>
        <select
          value={preferences.cookingTime}
          onChange={e => setPreferences(prev => ({ ...prev, cookingTime: e.target.value }))}
          className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-gray-900 dark:text-white"
        >
          {cookingTimes.map(time => (
            <option key={time.id} value={time.id}>
              {time.name}
            </option>
          ))}
        </select>
      </div>

      {/* Meal Timing Preferences */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Preferred Meal Times
        </label>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
          {mealTimings.map(timing => (
            <button
              key={timing}
              type="button"
              onClick={() => toggleItem(timing, 'mealTiming')}
              className={`p-3 rounded-lg border text-sm font-medium transition-colors ${
                preferences.mealTiming.includes(timing)
                  ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300'
                  : 'border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:border-blue-300'
              }`}
            >
              {timing}
            </button>
          ))}
        </div>
      </div>

      {/* Dietary Restrictions */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Dietary Restrictions
        </label>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {restrictions.map(restriction => (
            <button
              key={restriction}
              type="button"
              onClick={() => toggleItem(restriction, 'restrictions')}
              className={`p-3 rounded-lg border text-sm font-medium transition-colors ${
                preferences.restrictions.includes(restriction)
                  ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300'
                  : 'border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:border-blue-300'
              }`}
            >
              {restriction}
            </button>
          ))}
        </div>
      </div>

      {/* Food Preferences */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Food Preferences
        </label>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {foodPreferences.map(pref => (
            <button
              key={pref}
              type="button"
              onClick={() => toggleItem(pref, 'preferences')}
              className={`p-3 rounded-lg border text-sm font-medium transition-colors ${
                preferences.preferences.includes(pref)
                  ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300'
                  : 'border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:border-blue-300'
              }`}
            >
              {pref}
            </button>
          ))}
        </div>
      </div>

      {/* Exclude Ingredients */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Exclude Ingredients
        </label>
        <textarea
          value={preferences.excludeIngredients.join(', ')}
          onChange={handleExcludeIngredients}
          placeholder="Enter ingredients to exclude, separated by commas (e.g., mushrooms, olives, bell peppers)"
          className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-gray-900 dark:text-white h-24"
        />
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          List any ingredients you want to avoid in your meal plan
        </p>
      </div>

      <button
        type="submit"
        disabled={isLoading}
        className="w-full py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
      >
        {isLoading ? 'Generating Plan...' : 'Generate Meal Plan'}
      </button>
    </form>
  );
}