import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AnalyzerPage from './pages/AnalyzerPage';
import ChatPage from './pages/ChatPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import MenuAnalyzerPage from './pages/MenuAnalyzerPage';
import MealPlannerPage from './pages/MealPlannerPage';
import RestaurantFinderPage from './pages/RestaurantFinderPage';
import BlogPage from './pages/BlogPage';
import PrivacyPolicy from './components/policies/PrivacyPolicy';
import TermsOfService from './components/policies/TermsOfService';
import RefundPolicy from './components/policies/RefundPolicy';
import TawkToWidget from './components/TawkToWidget';

export default function App() {
  const [isDarkMode, setIsDarkMode] = React.useState(true);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle('dark');
  };

  React.useEffect(() => {
    document.documentElement.classList.add('dark');
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <AuthProvider>
          <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
            <div className="bg-white dark:bg-gray-900">
              <Navbar 
                isDarkMode={isDarkMode} 
                toggleDarkMode={toggleDarkMode}
              />

              <main className="pt-16">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/signup" element={<SignupPage />} />
                  <Route path="/blog" element={<BlogPage />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/terms" element={<TermsOfService />} />
                  <Route path="/refund" element={<RefundPolicy />} />
                  <Route 
                    path="/analyzer" 
                    element={
                      <PrivateRoute>
                        <AnalyzerPage />
                      </PrivateRoute>
                    } 
                  />
                  <Route 
                    path="/menu-analyzer" 
                    element={
                      <PrivateRoute>
                        <MenuAnalyzerPage />
                      </PrivateRoute>
                    } 
                  />
                  <Route 
                    path="/meal-planner" 
                    element={
                      <PrivateRoute>
                        <MealPlannerPage />
                      </PrivateRoute>
                    } 
                  />
                  <Route 
                    path="/restaurant-finder" 
                    element={
                      <PrivateRoute>
                        <RestaurantFinderPage />
                      </PrivateRoute>
                    } 
                  />
                  <Route 
                    path="/chat" 
                    element={
                      <PrivateRoute>
                        <ChatPage />
                      </PrivateRoute>
                    } 
                  />
                </Routes>
              </main>

              <Footer />
            </div>
            <TawkToWidget />
          </div>
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
}