import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { MealPlan } from '../types';
import { generateMealPlan } from '../services/mealPlanService';
import MealPlanForm from '../components/meal-planner/MealPlanForm';
import MealPlanResults from '../components/meal-planner/MealPlanResults';

export default function MealPlannerPage() {
  const [mealPlan, setMealPlan] = useState<MealPlan | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleGeneratePlan = async (preferences: any) => {
    setIsLoading(true);
    setError(null);

    try {
      const plan = await generateMealPlan(preferences);
      setMealPlan(plan);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to generate meal plan');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>AI Meal Planner | Personalized Diet Plans | Calofood</title>
        <meta 
          name="description" 
          content="Get personalized meal plans tailored to your dietary needs and health goals. Our AI creates balanced, nutritious meal plans that fit your lifestyle." 
        />
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            AI Meal Planner
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Get personalized meal plans based on your preferences
          </p>
        </div>

        {error && (
          <div className="mb-8 p-4 bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-300 rounded-lg">
            {error}
          </div>
        )}

        {mealPlan ? (
          <MealPlanResults 
            plan={mealPlan} 
            onBack={() => setMealPlan(null)} 
          />
        ) : (
          <MealPlanForm 
            onSubmit={handleGeneratePlan} 
            isLoading={isLoading} 
          />
        )}
      </div>
    </>
  );
}