import React from 'react';
import { 
  BarChart, 
  Heart, 
  Calendar, 
  Activity, 
  LineChart, 
  Users,
} from 'lucide-react';

const features = [
  {
    icon: BarChart,
    title: 'Detailed Nutrition Analysis',
    description: "From calories to micronutrients, know exactly what's in your food",
  },
  {
    icon: Heart,
    title: 'Health Score & Suggestions',
    description: 'Get a health score for each meal and tips on improving nutritional value',
  },
  {
    icon: Calendar,
    title: 'Personalized Meal Planning',
    description: 'Suggestions for your next meal based on your dietary needs',
  },
  {
    icon: Activity,
    title: 'Diet & Fitness Integration',
    description: 'Sync with your fitness goals to optimize meal suggestions for workouts and more',
  },
  {
    icon: LineChart,
    title: 'Tracking and Reports',
    description: 'View your progress over time with daily, weekly, and monthly reports',
  },
  {
    icon: Users,
    title: 'Community Engagement',
    description: 'Share your meals and tips with a community of health enthusiasts',
  },
];

export default function Features() {
  return (
    <section className="py-20 bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Features & Benefits
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Everything you need to maintain a healthy lifestyle
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-gray-50 dark:bg-gray-700 p-6 rounded-xl hover:shadow-lg transition-shadow"
            >
              <feature.icon className="w-10 h-10 text-blue-600 dark:text-blue-400 mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}