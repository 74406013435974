import { openaiConfig } from '../config/openai';
import { MealPlan } from '../types';
import { APIError } from '../utils/api';

interface MealPlanPreferences {
  calories: number;
  dietType: string;
  restrictions: string[];
  meals: number;
}

export async function generateMealPlan(preferences: MealPlanPreferences): Promise<MealPlan> {
  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: openaiConfig.headers,
      body: JSON.stringify({
        model: openaiConfig.models.default,
        messages: [
          {
            role: 'user',
            content: `Generate a detailed meal plan based on these preferences:
              Daily Calories: ${preferences.calories}
              Diet Type: ${preferences.dietType}
              Dietary Restrictions: ${preferences.restrictions.join(', ')}
              Number of Meals: ${preferences.meals}

              Format the response as a JSON object with these properties:
              {
                "dailyCalories": number,
                "meals": {
                  "breakfast": [
                    {
                      "name": string,
                      "calories": number,
                      "ingredients": string[],
                      "macros": {
                        "protein": number,
                        "carbs": number,
                        "fats": number
                      },
                      "instructions": string
                    }
                  ],
                  "lunch": [...],
                  "dinner": [...],
                  "snacks": [...]
                },
                "macros": {
                  "protein": number,
                  "carbs": number,
                  "fats": number
                }
              }`
          }
        ],
        temperature: 0.7,
        max_tokens: 1000,
        response_format: { type: "json_object" }
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new APIError(error.error?.message || 'Failed to generate meal plan', response.status);
    }

    const data = await response.json();
    const result = JSON.parse(data.choices[0].message.content);

    // Validate and sanitize the response
    return {
      dailyCalories: Number(result.dailyCalories) || preferences.calories,
      meals: {
        breakfast: Array.isArray(result.meals?.breakfast) ? result.meals.breakfast : [],
        lunch: Array.isArray(result.meals?.lunch) ? result.meals.lunch : [],
        dinner: Array.isArray(result.meals?.dinner) ? result.meals.dinner : [],
        snacks: Array.isArray(result.meals?.snacks) ? result.meals.snacks : []
      },
      macros: {
        protein: Number(result.macros?.protein) || 0,
        carbs: Number(result.macros?.carbs) || 0,
        fats: Number(result.macros?.fats) || 0
      }
    };
  } catch (error) {
    console.error('Meal plan generation error:', error);
    if (error instanceof APIError) {
      throw error;
    }
    throw new APIError('Failed to generate meal plan', 500);
  }
}