import { openaiConfig } from '../config/openai';
import { RestaurantSearchResults } from '../types';
import { APIError } from '../utils/api';

export async function searchRestaurants(preferences: {
  location: string;
  cuisine?: string;
  dietaryRestrictions: string[];
  priceRange: string;
  occasion?: string;
  time?: string;
}): Promise<RestaurantSearchResults> {
  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: openaiConfig.headers,
      body: JSON.stringify({
        model: openaiConfig.models.default,
        messages: [
          {
            role: 'user',
            content: `Find and recommend restaurants based on these preferences:
              Location: ${preferences.location}
              ${preferences.cuisine ? `Cuisine Type: ${preferences.cuisine}` : ''}
              Dietary Restrictions: ${preferences.dietaryRestrictions.join(', ')}
              Price Range: ${preferences.priceRange}
              ${preferences.occasion ? `Occasion: ${preferences.occasion}` : ''}
              ${preferences.time ? `Time: ${preferences.time}` : ''}

              Format the response as a JSON object with these properties:
              {
                "restaurants": [
                  {
                    "name": string,
                    "cuisine": string,
                    "priceRange": string,
                    "rating": number,
                    "address": string,
                    "description": string,
                    "dietaryOptions": string[],
                    "bestDishes": string[],
                    "atmosphere": string,
                    "reservationRecommended": boolean,
                    "busyHours": string,
                    "healthyOptions": string[]
                  }
                ],
                "recommendations": {
                  "healthyChoices": string[],
                  "popularDishes": string[],
                  "dietaryWarnings": string[]
                },
                "generalAdvice": string
              }`
          }
        ],
        temperature: 0.7,
        max_tokens: 1000,
        response_format: { type: "json_object" }
      })
    });

    if (!response.ok) {
      throw new APIError('Failed to search restaurants', response.status);
    }

    const data = await response.json();
    const result = JSON.parse(data.choices[0].message.content);

    return {
      restaurants: result.restaurants.map((restaurant: any) => ({
        name: restaurant.name || 'Unknown Restaurant',
        cuisine: restaurant.cuisine || 'Mixed Cuisine',
        priceRange: restaurant.priceRange || '$$$',
        rating: Number(restaurant.rating) || 4.0,
        address: restaurant.address || 'Address not available',
        description: restaurant.description || '',
        dietaryOptions: Array.isArray(restaurant.dietaryOptions) ? restaurant.dietaryOptions : [],
        bestDishes: Array.isArray(restaurant.bestDishes) ? restaurant.bestDishes : [],
        atmosphere: restaurant.atmosphere || '',
        reservationRecommended: Boolean(restaurant.reservationRecommended),
        busyHours: restaurant.busyHours || '',
        healthyOptions: Array.isArray(restaurant.healthyOptions) ? restaurant.healthyOptions : []
      })),
      recommendations: {
        healthyChoices: Array.isArray(result.recommendations?.healthyChoices) 
          ? result.recommendations.healthyChoices 
          : [],
        popularDishes: Array.isArray(result.recommendations?.popularDishes)
          ? result.recommendations.popularDishes
          : [],
        dietaryWarnings: Array.isArray(result.recommendations?.dietaryWarnings)
          ? result.recommendations.dietaryWarnings
          : []
      },
      generalAdvice: result.generalAdvice || ''
    };
  } catch (error) {
    console.error('Restaurant search error:', error);
    if (error instanceof APIError) {
      throw error;
    }
    throw new APIError('Failed to search restaurants', 500);
  }
}