import React from 'react';

export default function RefundPolicy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-16 mt-16">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Refund Policy</h1>
      <div className="prose dark:prose-invert max-w-none">
        <p className="text-gray-600 dark:text-gray-300 mb-4">Effective Date: November 5, 2024</p>

        <h2>1. Subscription Plans</h2>
        <p>Calofood offers both Standard and Unlimited subscription plans, billed monthly or annually. Each subscription provides full access to the app's features within the scan limits of the chosen plan.</p>

        <h2>2. Refund Eligibility</h2>
        <p>Refunds are issued only in the following cases:</p>
        <ul>
          <li>Technical Issues: If a significant technical issue prevents you from using the app and we are unable to resolve it within a reasonable timeframe.</li>
          <li>Duplicate Charges: If you were inadvertently billed multiple times for the same subscription period.</li>
        </ul>

        <h2>3. Non-Refundable Circumstances</h2>
        <p>We do not offer refunds for:</p>
        <ul>
          <li>Unused scan limits within a billing cycle</li>
          <li>User dissatisfaction due to app functionality when used as intended</li>
          <li>Change of mind or accidental purchases</li>
        </ul>

        <h2>4. Requesting a Refund</h2>
        <p>To request a refund, please contact our support team at contact@calofood.com within 30 days of the charge. Include your account information and reason for the request. Refunds will be processed within 10-15 business days, depending on your payment method.</p>

        <h2>5. Changes to Refund Policy</h2>
        <p>We may update this Refund Policy as necessary. Changes will be effective when posted on our website.</p>
      </div>
    </div>
  );
}