import React, { useState } from 'react';
import { BlogPost } from '../types';
import ArticlePage from '../components/blog/ArticlePage';
import Blog from '../components/home/Blog';

// This would typically come from an API or database
const articles: BlogPost[] = [
  {
    id: '1',
    title: 'Understanding Calories: A Complete Guide to Better Nutrition',
    description: 'Learn everything you need to know about calories, how they affect your body, and how to manage them effectively for optimal health.',
    content: `
      <h2>What are Calories?</h2>
      <p>Calories are units of energy that measure how much energy food provides to the body...</p>
      
      <h2>Why Calories Matter</h2>
      <p>Understanding calorie intake is crucial for maintaining a healthy weight...</p>
      
      <h2>How to Track Calories Effectively</h2>
      <p>Modern technology has made calorie tracking easier than ever...</p>
    `,
    image: 'https://images.unsplash.com/photo-1490645935967-10de6ba17061?auto=format&fit=crop&q=80&w=800',
    date: '2024-03-15',
    readTime: '8 min read',
    slug: 'understanding-calories-guide',
    category: 'Nutrition'
  },
  // ... other articles
];

export default function BlogPage() {
  const [selectedArticle, setSelectedArticle] = useState<BlogPost | null>(null);

  return (
    <div className="pt-16">
      {selectedArticle ? (
        <ArticlePage
          article={selectedArticle}
          onBack={() => setSelectedArticle(null)}
        />
      ) : (
        <Blog onArticleClick={setSelectedArticle} articles={articles} />
      )}
    </div>
  );
}