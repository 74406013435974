import React from 'react';
import { ArrowLeft, Clock, Flame, Beef, Cookie, Droplets } from 'lucide-react';
import { MealPlan, Meal } from '../../types';

interface MealPlanResultsProps {
  plan: MealPlan;
  onBack: () => void;
}

const MealSection = ({ title, meals }: { title: string; meals: Meal[] }) => (
  <div className="space-y-4">
    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{title}</h3>
    {meals.map((meal, index) => (
      <div 
        key={index}
        className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm"
      >
        <div className="flex justify-between items-start mb-4">
          <h4 className="text-lg font-medium text-gray-900 dark:text-white">
            {meal.name}
          </h4>
          <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
            <Flame className="w-4 h-4" />
            <span>{meal.calories} cal</span>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <h5 className="font-medium text-gray-900 dark:text-white mb-2">
              Ingredients
            </h5>
            <ul className="space-y-2">
              {meal.ingredients.map((ingredient, i) => (
                <li 
                  key={i}
                  className="flex items-center gap-2 text-gray-600 dark:text-gray-300"
                >
                  <span className="w-1.5 h-1.5 bg-blue-500 rounded-full" />
                  {ingredient}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h5 className="font-medium text-gray-900 dark:text-white mb-2">
              Nutrition
            </h5>
            <div className="grid grid-cols-3 gap-4">
              <div className="space-y-1">
                <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
                  <Beef className="w-4 h-4" />
                  <span>Protein</span>
                </div>
                <span className="font-medium text-gray-900 dark:text-white">
                  {meal.macros.protein}g
                </span>
              </div>
              <div className="space-y-1">
                <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
                  <Cookie className="w-4 h-4" />
                  <span>Carbs</span>
                </div>
                <span className="font-medium text-gray-900 dark:text-white">
                  {meal.macros.carbs}g
                </span>
              </div>
              <div className="space-y-1">
                <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
                  <Droplets className="w-4 h-4" />
                  <span>Fats</span>
                </div>
                <span className="font-medium text-gray-900 dark:text-white">
                  {meal.macros.fats}g
                </span>
              </div>
            </div>
          </div>
        </div>

        {meal.instructions && (
          <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
            <h5 className="font-medium text-gray-900 dark:text-white mb-2">
              Instructions
            </h5>
            <p className="text-gray-600 dark:text-gray-300">
              {meal.instructions}
            </p>
          </div>
        )}
      </div>
    ))}
  </div>
);

export default function MealPlanResults({ plan, onBack }: MealPlanResultsProps) {
  return (
    <div className="space-y-8">
      {/* Header */}
      <div className="flex items-center justify-between">
        <button
          onClick={onBack}
          className="flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
        >
          <ArrowLeft className="w-5 h-5" />
          Create New Plan
        </button>
        <div className="flex items-center gap-2 bg-blue-100 dark:bg-blue-900/20 text-blue-800 dark:text-blue-200 px-4 py-2 rounded-full">
          <Clock className="w-4 h-4" />
          <span className="font-medium">{plan.dailyCalories} calories/day</span>
        </div>
      </div>

      {/* Daily Overview */}
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-gradient-to-br from-red-500 to-orange-600 rounded-xl p-4 text-white">
          <div className="flex items-center gap-2 mb-2">
            <Beef className="w-5 h-5" />
            <h3 className="font-medium">Protein</h3>
          </div>
          <p className="text-2xl font-bold">{plan.macros.protein}g</p>
        </div>

        <div className="bg-gradient-to-br from-amber-500 to-yellow-600 rounded-xl p-4 text-white">
          <div className="flex items-center gap-2 mb-2">
            <Cookie className="w-5 h-5" />
            <h3 className="font-medium">Carbs</h3>
          </div>
          <p className="text-2xl font-bold">{plan.macros.carbs}g</p>
        </div>

        <div className="bg-gradient-to-br from-blue-500 to-indigo-600 rounded-xl p-4 text-white">
          <div className="flex items-center gap-2 mb-2">
            <Droplets className="w-5 h-5" />
            <h3 className="font-medium">Fats</h3>
          </div>
          <p className="text-2xl font-bold">{plan.macros.fats}g</p>
        </div>
      </div>

      {/* Meal Sections */}
      <div className="space-y-8">
        <MealSection title="Breakfast" meals={plan.meals.breakfast} />
        <MealSection title="Lunch" meals={plan.meals.lunch} />
        <MealSection title="Dinner" meals={plan.meals.dinner} />
        {plan.meals.snacks.length > 0 && (
          <MealSection title="Snacks" meals={plan.meals.snacks} />
        )}
      </div>
    </div>
  );
}