import React from 'react';
import { Calendar, Clock, ArrowLeft } from 'lucide-react';
import { BlogPost } from '../../types';

interface ArticlePageProps {
  article: BlogPost;
  onBack: () => void;
}

export default function ArticlePage({ article, onBack }: ArticlePageProps) {
  return (
    <article className="max-w-4xl mx-auto px-4 py-12">
      <button
        onClick={onBack}
        className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-700 mb-8"
      >
        <ArrowLeft size={20} />
        Back to Articles
      </button>

      <img
        src={article.image}
        alt={article.title}
        className="w-full h-[400px] object-cover rounded-2xl mb-8"
      />

      <div className="prose prose-lg dark:prose-invert max-w-none">
        <div className="flex items-center gap-6 text-gray-600 dark:text-gray-400 mb-6">
          <span className="inline-flex items-center gap-2">
            <Calendar size={20} />
            {new Date(article.date).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })}
          </span>
          <span className="inline-flex items-center gap-2">
            <Clock size={20} />
            {article.readTime}
          </span>
          <span className="inline-block px-3 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 text-sm rounded-full">
            {article.category}
          </span>
        </div>

        <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-6">
          {article.title}
        </h1>

        <div 
          className="text-gray-700 dark:text-gray-300"
          dangerouslySetInnerHTML={{ __html: article.content }}
        />
      </div>
    </article>
  );
}