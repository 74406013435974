import React from 'react';

export default function TermsOfService() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-16 mt-16">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Terms of Service</h1>
      <div className="prose dark:prose-invert max-w-none">
        <p className="text-gray-600 dark:text-gray-300 mb-4">Effective Date: November 5, 2024</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By using Calofood, you agree to these Terms of Service. If you do not agree, please do not use the app.</p>

        <h2>2. User Accounts</h2>
        <p>To use certain features, you must create an account. You are responsible for maintaining the confidentiality of your account and password.</p>

        <h2>3. Use of Services</h2>
        <p>You agree to use the app only for personal, non-commercial purposes. Unauthorized resale or distribution of the app's features is prohibited.</p>

        <h2>4. Payment and Subscription</h2>
        <p>Paid users are billed based on the selected plan (Standard or Unlimited). Subscription fees are charged on a monthly or annual basis and are non-refundable, except as specified in our Refund Policy.</p>

        <h2>5. Limitations of Liability</h2>
        <p>Calofood provides nutritional information as a general guideline and is not a substitute for professional medical advice. We are not liable for any health or dietary outcomes resulting from app use.</p>

        <h2>6. Intellectual Property</h2>
        <p>All content, features, and functionality are the exclusive property of Calofood and its licensors. Unauthorized use, duplication, or distribution is prohibited.</p>

        <h2>7. Termination</h2>
        <p>We reserve the right to terminate or suspend your account for violations of these Terms.</p>

        <h2>8. Changes to Terms</h2>
        <p>We may update these Terms from time to time. Continued use of the app after any changes constitutes acceptance of the new Terms.</p>

        <h2>Contact Us</h2>
        <p>For questions regarding these Terms, contact us at contact@calofood.com.</p>
      </div>
    </div>
  );
}