import { APIError } from '../utils/api';

const OPENAI_API_KEY = import.meta.env.VITE_OPENAI_API_KEY;

if (!OPENAI_API_KEY) {
  console.error('OpenAI API key is not set. Please add it to your .env file.');
}

export const openaiConfig = {
  apiKey: OPENAI_API_KEY,
  models: {
    default: 'gpt-4o-mini',
    vision: 'gpt-4o-mini'
  },
  headers: {
    'Authorization': `Bearer ${OPENAI_API_KEY}`,
    'Content-Type': 'application/json'
  },
  timeout: 30000,
  maxRetries: 3,
  validateResponse: (data: any) => {
    if (!data?.choices?.[0]?.message?.content) {
      throw new APIError('Invalid response format from OpenAI', 500);
    }
    return data.choices[0].message.content;
  }
};