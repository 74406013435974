import React, { useRef, useState } from 'react';
import { Camera as CameraIcon, X, Image as ImageIcon, ArrowLeft, Scan, Loader2 } from 'lucide-react';
import Webcam from 'react-webcam';

interface CameraProps {
  onResult: (imageData: string) => Promise<void>;
  onError: (error: string) => void;
  mode?: 'food' | 'menu';
}

export default function Camera({ onResult, onError, mode = 'food' }: CameraProps) {
  const [image, setImage] = useState<string | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const webcamRef = useRef<Webcam>(null);

  const handleImageSelect = async (files: FileList) => {
    const file = files[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      onError('Please select a valid image file');
      return;
    }

    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result as string;
        setImage(base64);
      };
      reader.onerror = () => {
        onError('Failed to read image file');
      };
      reader.readAsDataURL(file);
    } catch (error) {
      onError('Failed to process image');
    }
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setImage(imageSrc);
      setShowCamera(false);
    } else {
      onError('Failed to capture photo');
    }
  };

  const handleAnalyze = async () => {
    if (!image) return;
    
    setIsAnalyzing(true);
    try {
      await onResult(image);
      setImage(null);
    } catch (error) {
      onError(error instanceof Error ? error.message : 'Failed to analyze image');
    } finally {
      setIsAnalyzing(false);
    }
  };

  if (showCamera) {
    return (
      <div className="relative">
        <button
          onClick={() => setShowCamera(false)}
          className="absolute top-4 left-4 z-10 p-2 bg-black/50 rounded-full text-white hover:bg-black/70 transition-colors"
        >
          <ArrowLeft size={24} />
        </button>
        
        <div className="relative rounded-xl overflow-hidden bg-black aspect-[4/3]">
          <Webcam
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className="w-full h-full object-cover"
            videoConstraints={{
              facingMode: 'environment',
              aspectRatio: 4/3
            }}
          />
          
          <button
            onClick={capturePhoto}
            className="absolute bottom-4 left-1/2 -translate-x-1/2 w-16 h-16 bg-white rounded-full flex items-center justify-center hover:bg-gray-100 transition-colors"
          >
            <div className="w-14 h-14 rounded-full border-4 border-black" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={(e) => e.target.files && handleImageSelect(e.target.files)}
          className="hidden"
        />
        <button
          onClick={() => fileInputRef.current?.click()}
          className="p-4 bg-gray-100 dark:bg-gray-800 rounded-xl flex flex-col items-center justify-center gap-2 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          disabled={isAnalyzing}
        >
          <ImageIcon size={24} className="text-gray-700 dark:text-gray-300" />
          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
            Upload Photo
          </span>
        </button>
        <button
          onClick={() => setShowCamera(true)}
          className="p-4 bg-gray-100 dark:bg-gray-800 rounded-xl flex flex-col items-center justify-center gap-2 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          disabled={isAnalyzing}
        >
          <CameraIcon size={24} className="text-gray-700 dark:text-gray-300" />
          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
            Take Photo
          </span>
        </button>
      </div>

      {image && (
        <div className="space-y-4">
          <div className="relative aspect-[4/3] rounded-xl overflow-hidden">
            <img
              src={image}
              alt="Selected"
              className="w-full h-full object-cover"
            />
            <button
              onClick={() => setImage(null)}
              className="absolute top-2 right-2 p-1 bg-black/50 rounded-full text-white hover:bg-black/70 transition-colors"
              disabled={isAnalyzing}
            >
              <X size={16} />
            </button>
          </div>

          <button
            onClick={handleAnalyze}
            disabled={isAnalyzing}
            className="w-full px-6 py-3 bg-blue-600 text-white rounded-xl flex items-center justify-center gap-2 hover:bg-blue-700 transition-colors disabled:opacity-50"
          >
            {isAnalyzing ? (
              <>
                <Loader2 className="w-5 h-5 animate-spin" />
                Analyzing...
              </>
            ) : (
              <>
                <Scan className="w-5 h-5" />
                Analyze {mode === 'menu' ? 'Menu' : 'Food'}
              </>
            )}
          </button>
        </div>
      )}

      {!image && (
        <div className="text-center p-8 bg-gray-50 dark:bg-gray-800/50 rounded-xl">
          <ImageIcon size={48} className="mx-auto mb-4 text-gray-400" />
          <p className="text-gray-500 dark:text-gray-400">
            Take a photo or upload from your library
          </p>
        </div>
      )}
    </div>
  );
}