import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Sun, 
  Moon, 
  User, 
  LogOut, 
  ChevronDown, 
  Scan,
  MessageSquare,
  Menu as MenuIcon,
  Utensils,
  Calendar,
  MapPin
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import Logo from './Logo';

interface NavbarProps {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

const labTools = [
  {
    name: 'Food Analyzer',
    description: 'Analyze food images for nutrition info',
    icon: Scan,
    path: '/analyzer'
  },
  {
    name: 'Menu Analyzer',
    description: 'Get healthy recommendations from restaurant menus',
    icon: Utensils,
    path: '/menu-analyzer'
  },
  {
    name: 'Restaurant Finder',
    description: 'Find restaurants based on your preferences',
    icon: MapPin,
    path: '/restaurant-finder'
  },
  {
    name: 'Meal Planner',
    description: 'Get personalized meal plans',
    icon: Calendar,
    path: '/meal-planner'
  },
  {
    name: 'Nutrition Chat',
    description: 'Chat with our AI nutrition expert',
    icon: MessageSquare,
    path: '/chat'
  }
];

export default function Navbar({ isDarkMode, toggleDarkMode }: NavbarProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showLabMenu, setShowLabMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const isHome = location.pathname === '/';

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleHomeClick = () => {
    navigate('/');
    if (showMobileMenu) {
      setShowMobileMenu(false);
    }
  };

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (showMobileMenu) {
      setShowMobileMenu(false);
    }
  };

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm border-b border-gray-200 dark:border-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <Link to="/" onClick={handleHomeClick}>
            <Logo />
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center gap-6">
            <button 
              onClick={handleHomeClick}
              className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
            >
              Home
            </button>

            {/* Nutrition Lab Dropdown */}
            {currentUser && (
              <div className="relative">
                <button
                  onClick={() => setShowLabMenu(!showLabMenu)}
                  className="flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                >
                  Nutrition Lab
                  <ChevronDown className="w-4 h-4" />
                </button>

                {showLabMenu && (
                  <div 
                    className="absolute left-0 mt-2 w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg py-1 ring-1 ring-black ring-opacity-5"
                    onMouseLeave={() => setShowLabMenu(false)}
                  >
                    {labTools.map((tool) => (
                      <Link
                        key={tool.path}
                        to={tool.path}
                        className="flex items-start gap-3 px-4 py-3 hover:bg-gray-50 dark:hover:bg-gray-700"
                        onClick={() => setShowLabMenu(false)}
                      >
                        <tool.icon className="w-5 h-5 text-gray-400 dark:text-gray-500 mt-0.5" />
                        <div>
                          <div className="font-medium text-gray-900 dark:text-white">
                            {tool.name}
                          </div>
                          <div className="text-sm text-gray-500 dark:text-gray-400">
                            {tool.description}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            )}

            {isHome && (
              <>
                <button 
                  onClick={() => scrollToSection('features')}
                  className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                >
                  Features
                </button>
                <button 
                  onClick={() => scrollToSection('pricing')}
                  className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                >
                  Pricing
                </button>
              </>
            )}
          </nav>

          <div className="flex items-center gap-4">
            {currentUser ? (
              <div className="relative">
                <button
                  onClick={() => setShowUserMenu(!showUserMenu)}
                  className="flex items-center gap-2 text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                >
                  <div className="w-8 h-8 bg-blue-100 dark:bg-blue-900 rounded-full flex items-center justify-center">
                    <User className="w-4 h-4 text-blue-600 dark:text-blue-400" />
                  </div>
                </button>

                {showUserMenu && (
                  <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg py-1">
                    <div className="px-4 py-2 border-b border-gray-200 dark:border-gray-700">
                      <div className="text-sm font-medium text-gray-900 dark:text-white truncate">
                        {currentUser.email}
                      </div>
                    </div>
                    <button
                      onClick={handleLogout}
                      className="flex items-center gap-2 w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <LogOut className="w-4 h-4" />
                      Sign Out
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <Link 
                  to="/login"
                  className="text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white px-4 py-2"
                >
                  Sign In
                </Link>
                <Link 
                  to="/signup"
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Sign Up
                </Link>
              </div>
            )}

            <button
              onClick={toggleDarkMode}
              className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
            >
              {isDarkMode ? (
                <Sun className="text-white" size={20} />
              ) : (
                <Moon className="text-gray-900" size={20} />
              )}
            </button>

            {/* Mobile Menu Button */}
            <button
              className="md:hidden p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <MenuIcon className="w-6 h-6 text-gray-600 dark:text-gray-300" />
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {showMobileMenu && (
          <nav className="md:hidden py-4 border-t border-gray-200 dark:border-gray-700">
            <div className="flex flex-col gap-4">
              <button 
                onClick={handleHomeClick}
                className="text-left text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
              >
                Home
              </button>

              {currentUser && (
                <div className="space-y-2">
                  <div className="font-medium text-gray-900 dark:text-white px-2">
                    Nutrition Lab
                  </div>
                  {labTools.map((tool) => (
                    <Link
                      key={tool.path}
                      to={tool.path}
                      className="flex items-center gap-2 px-2 py-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                      onClick={() => setShowMobileMenu(false)}
                    >
                      <tool.icon className="w-5 h-5" />
                      {tool.name}
                    </Link>
                  ))}
                </div>
              )}

              {isHome && (
                <>
                  <button 
                    onClick={() => {
                      scrollToSection('features');
                      setShowMobileMenu(false);
                    }}
                    className="text-left text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                  >
                    Features
                  </button>
                  <button 
                    onClick={() => {
                      scrollToSection('pricing');
                      setShowMobileMenu(false);
                    }}
                    className="text-left text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                  >
                    Pricing
                  </button>
                </>
              )}
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}