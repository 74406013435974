import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyC4WbRo4IDg6idD2wrsvRAG-FeR-ICbDTY",
  authDomain: "calofood.firebaseapp.com",
  projectId: "calofood",
  storageBucket: "calofood.firebasestorage.app",
  messagingSenderId: "55052614954",
  appId: "1:55052614954:web:0fab365821018334343253",
  measurementId: "G-QX8MZ8G8QR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);