import React, { useState } from 'react';
import { MapPin, Clock, DollarSign, Utensils, Calendar } from 'lucide-react';

interface PreferenceFormProps {
  onSubmit: (preferences: any) => void;
  isLoading: boolean;
}

export default function PreferenceForm({ onSubmit, isLoading }: PreferenceFormProps) {
  const [preferences, setPreferences] = useState({
    location: '',
    cuisine: '',
    dietaryRestrictions: [] as string[],
    priceRange: '$$',
    occasion: '',
    time: ''
  });

  const cuisineTypes = [
    'Any',
    'Italian',
    'Japanese',
    'Chinese',
    'Mexican',
    'Indian',
    'Mediterranean',
    'American',
    'Thai',
    'French',
    'Korean'
  ];

  const dietaryRestrictions = [
    'Vegetarian',
    'Vegan',
    'Gluten-free',
    'Dairy-free',
    'Halal',
    'Kosher',
    'Nut-free',
    'Low-carb'
  ];

  const occasions = [
    'Casual Dining',
    'Date Night',
    'Business Meal',
    'Family Dinner',
    'Special Occasion',
    'Quick Bite'
  ];

  const toggleRestriction = (restriction: string) => {
    setPreferences(prev => ({
      ...prev,
      dietaryRestrictions: prev.dietaryRestrictions.includes(restriction)
        ? prev.dietaryRestrictions.filter(r => r !== restriction)
        : [...prev.dietaryRestrictions, restriction]
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(preferences);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      {/* Location */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          <div className="flex items-center gap-2">
            <MapPin className="w-4 h-4" />
            Location
          </div>
        </label>
        <input
          type="text"
          required
          placeholder="Enter city or neighborhood"
          value={preferences.location}
          onChange={e => setPreferences(prev => ({ ...prev, location: e.target.value }))}
          className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-gray-900 dark:text-white"
        />
      </div>

      {/* Cuisine Type */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          <div className="flex items-center gap-2">
            <Utensils className="w-4 h-4" />
            Cuisine Type
          </div>
        </label>
        <select
          value={preferences.cuisine}
          onChange={e => setPreferences(prev => ({ ...prev, cuisine: e.target.value }))}
          className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-gray-900 dark:text-white"
        >
          <option value="">Any Cuisine</option>
          {cuisineTypes.map(cuisine => (
            <option key={cuisine} value={cuisine}>
              {cuisine}
            </option>
          ))}
        </select>
      </div>

      {/* Price Range */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          <div className="flex items-center gap-2">
            <DollarSign className="w-4 h-4" />
            Price Range
          </div>
        </label>
        <div className="flex gap-4">
          {['$', '$$', '$$$', '$$$$'].map(price => (
            <button
              key={price}
              type="button"
              onClick={() => setPreferences(prev => ({ ...prev, priceRange: price }))}
              className={`flex-1 py-2 rounded-lg border text-sm font-medium transition-colors ${
                preferences.priceRange === price
                  ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300'
                  : 'border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:border-blue-300'
              }`}
            >
              {price}
            </button>
          ))}
        </div>
      </div>

      {/* Occasion */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          <div className="flex items-center gap-2">
            <Calendar className="w-4 h-4" />
            Occasion
          </div>
        </label>
        <select
          value={preferences.occasion}
          onChange={e => setPreferences(prev => ({ ...prev, occasion: e.target.value }))}
          className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-gray-900 dark:text-white"
        >
          <option value="">Any Occasion</option>
          {occasions.map(occasion => (
            <option key={occasion} value={occasion}>
              {occasion}
            </option>
          ))}
        </select>
      </div>

      {/* Time */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          <div className="flex items-center gap-2">
            <Clock className="w-4 h-4" />
            Preferred Time
          </div>
        </label>
        <input
          type="time"
          value={preferences.time}
          onChange={e => setPreferences(prev => ({ ...prev, time: e.target.value }))}
          className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-gray-900 dark:text-white"
        />
      </div>

      {/* Dietary Restrictions */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Dietary Restrictions
        </label>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
          {dietaryRestrictions.map(restriction => (
            <button
              key={restriction}
              type="button"
              onClick={() => toggleRestriction(restriction)}
              className={`p-3 rounded-lg border text-sm font-medium transition-colors ${
                preferences.dietaryRestrictions.includes(restriction)
                  ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300'
                  : 'border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:border-blue-300'
              }`}
            >
              {restriction}
            </button>
          ))}
        </div>
      </div>

      <button
        type="submit"
        disabled={isLoading || !preferences.location}
        className="w-full py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
      >
        {isLoading ? 'Searching...' : 'Find Restaurants'}
      </button>
    </form>
  );
}