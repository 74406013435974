import React from 'react';
import { Bot, User, AlertCircle, Volume2, VolumeX } from 'lucide-react';
import { Message } from '../../services/chatService';

interface ChatMessageProps {
  message: Message;
  onPlay: (text: string) => void;
  onStop: () => void;
  isSpeaking: boolean;
  voiceEnabled: boolean;
}

export default function ChatMessage({ 
  message, 
  onPlay, 
  onStop,
  isSpeaking,
  voiceEnabled 
}: ChatMessageProps) {
  const isUser = message.role === 'user';

  const handleVoiceClick = () => {
    if (isSpeaking) {
      onStop();
    } else {
      onPlay(message.content);
    }
  };

  return (
    <div className={`group flex gap-4 ${isUser ? 'flex-row-reverse' : ''}`}>
      <div className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${
        isUser ? 'bg-blue-100 dark:bg-blue-900' : 'bg-green-100 dark:bg-green-900'
      }`}>
        {isUser ? (
          <User className="w-5 h-5 text-blue-600 dark:text-blue-400" />
        ) : (
          <Bot className="w-5 h-5 text-green-600 dark:text-green-400" />
        )}
      </div>
      
      <div className={`flex-1 max-w-[80%] ${isUser ? 'text-right' : ''}`}>
        <div className={`relative inline-block rounded-2xl px-4 py-2 ${
          isUser 
            ? 'bg-blue-600 text-white' 
            : message.error 
              ? 'bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-300' 
              : 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white'
        }`}>
          {message.error && (
            <div className="flex items-center gap-2 mb-2">
              <AlertCircle className="w-5 h-5" />
              Error
            </div>
          )}
          <p className="whitespace-pre-wrap">{message.content}</p>

          {/* Voice control for assistant messages */}
          {!isUser && voiceEnabled && (
            <button
              onClick={handleVoiceClick}
              className={`absolute -right-10 top-2 p-1.5 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity ${
                isSpeaking ? 'text-blue-500' : 'text-gray-400 hover:text-gray-600'
              }`}
            >
              {isSpeaking ? (
                <VolumeX className="w-4 h-4" />
              ) : (
                <Volume2 className="w-4 h-4" />
              )}
            </button>
          )}
        </div>
        <div className={`text-xs text-gray-500 dark:text-gray-400 mt-1 ${
          isUser ? 'text-right' : ''
        }`}>
          {new Date(message.timestamp).toLocaleTimeString()}
        </div>
      </div>
    </div>
  );
}