import React, { useState } from 'react';
import Camera from './Camera';
import Results from './Results';
import { analyzeImage } from '../services/imageAnalysis';
import { AnalysisResponse } from '../types';

export default function Scanner() {
  const [result, setResult] = useState<AnalysisResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageCapture = async (imageData: string) => {
    setIsLoading(true);
    setError(null);
    
    try {
      const analysis = await analyzeImage(imageData);
      setResult(analysis);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to analyze image');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setResult(null);
    setError(null);
  };

  return (
    <section className="py-10">
      <div className="max-w-4xl mx-auto px-4">
        {error && (
          <div className="mb-8 p-4 bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-300 rounded-xl">
            {error}
          </div>
        )}

        {result ? (
          <Results
            result={result}
            isLoading={isLoading}
            onBack={handleBack}
          />
        ) : (
          <div className="max-w-md mx-auto">
            <Camera
              onResult={handleImageCapture}
              onError={setError}
            />
          </div>
        )}
      </div>
    </section>
  );
}