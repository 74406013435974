import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-16 mt-16">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Privacy Policy</h1>
      <div className="prose dark:prose-invert max-w-none">
        <p className="text-gray-600 dark:text-gray-300 mb-4">Effective Date: November 5, 2024</p>

        <h2>1. Introduction</h2>
        <p>Calofood ("we," "us," or "our") respects your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your information when you use the Calofood app.</p>

        <h2>2. Information We Collect</h2>
        <p>We collect information to provide you with the best possible experience. This may include:</p>
        <ul>
          <li>Personal Information: Name, email address, and account information.</li>
          <li>Usage Data: Details of your app activity, such as scans and preferences.</li>
          <li>Device Information: IP address, device type, operating system, and browser.</li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>Your information is used for:</p>
        <ul>
          <li>Providing and improving our services</li>
          <li>Personalizing your experience with tailored meal and nutrition suggestions</li>
          <li>Communicating with you regarding updates and offers</li>
          <li>Analyzing usage for app improvements</li>
        </ul>

        <h2>4. Information Sharing</h2>
        <p>We do not sell your information. We may share it only:</p>
        <ul>
          <li>With third-party providers for operational purposes (e.g., payment processing)</li>
          <li>To comply with legal requirements, such as court orders or regulatory inquiries</li>
        </ul>

        <h2>5. Data Security</h2>
        <p>We use industry-standard security measures to protect your information from unauthorized access, disclosure, or alteration. However, no system is entirely secure, and we cannot guarantee absolute security.</p>

        <h2>6. Your Choices</h2>
        <p>You may update or delete your information in the app settings. You can also opt out of marketing emails at any time by following the unsubscribe link.</p>

        <h2>7. Changes to This Policy</h2>
        <p>We may update this Privacy Policy from time to time. Changes will be posted here, and significant updates may be communicated through the app or by email.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions, please contact us at contact@calofood.com.</p>
      </div>
    </div>
  );
}