import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, Scan } from 'lucide-react';

export default function Hero() {
  const navigate = useNavigate();

  return (
    <div className="relative bg-gradient-to-br from-blue-600 to-indigo-700 text-white">
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:20px_20px]" />
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 md:py-32">
        <div className="text-center max-w-3xl mx-auto">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-100">
            Your Personal Nutrition Coach Powered by AI
          </h1>
          <p className="text-xl md:text-2xl text-blue-100 mb-8">
            Scan your meals, track calories, and get personalized health insights
          </p>
          <button
            onClick={() => navigate('/analyzer')}
            className="inline-flex items-center gap-2 bg-white text-blue-600 px-8 py-4 rounded-full text-lg font-semibold hover:bg-blue-50 transition-colors shadow-lg hover:shadow-xl"
          >
            <Scan className="w-5 h-5" />
            Try Food Analyzer
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}