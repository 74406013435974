import React from 'react';
import { Helmet } from 'react-helmet-async';
import Scanner from '../components/Scanner';

export default function AnalyzerPage() {
  return (
    <>
      <Helmet>
        <title>AI Food Analyzer | Calorie & Nutrition Analysis | Calofood</title>
        <meta 
          name="description" 
          content="Instantly analyze your food's nutritional content with our AI-powered food scanner. Get detailed calorie counts, macronutrients, and personalized health insights." 
        />
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            AI Food Analyzer
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Take a photo or upload an image of your food to get instant nutritional analysis
          </p>
        </div>
        <Scanner />
      </div>
    </>
  );
}